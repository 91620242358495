import { Process } from '../../../../../api/process';

export const filterByMacroProcessId = (
  processes: Process[],
  macroProcessId: number
) => {
  if (!macroProcessId) {
    return processes;
  }

  return processes.filter(
    (process) => process?.macroProcessId === macroProcessId
  );
};
