import { AxiosResponse } from 'axios';
import axios from './axiosConfig';

export interface TypeMacroProcess {
  id?: number;
  name: string;
  comments?: string;
  isActive: string;
  creationId: number;
}

const createTypeMacroProcess = async (
  typeMacroProcess: TypeMacroProcess
): Promise<AxiosResponse<TypeMacroProcess>> =>
  axios.post('/typeMacroProcesses', typeMacroProcess);
const updateTypeMacroProcess = async (
  id: number,
  typeMacroProcess: TypeMacroProcess
): Promise<AxiosResponse<TypeMacroProcess>> =>
  axios.patch(`/typeMacroProcesses/${id}`, typeMacroProcess);
const getTypeMacroProcesses = async (): Promise<
  AxiosResponse<TypeMacroProcess[]>
> => axios.get('/typeMacroProcesses');

const typeMacroProcessApi = {
  createTypeMacroProcess,
  updateTypeMacroProcess,
  getTypeMacroProcesses,
};

export default typeMacroProcessApi;
