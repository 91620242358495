import { Box } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import {
  DataGrid,
  gridClasses,
  GridRowsProp,
  GridColDef,
  esES,
  GridSortModel,
  GridRowIdGetter,
} from '@mui/x-data-grid';

type Props = {
  getRowId?: GridRowIdGetter;
  setSelectedRow: React.Dispatch<React.SetStateAction<any | undefined>>;
  rows: GridRowsProp;
  columns: GridColDef[];
  sortedBy?: GridSortModel;
};

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: '#0000001A',
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

export const MuiTable: React.FC<Props> = ({
  getRowId,
  setSelectedRow,
  rows,
  columns,
  sortedBy = [],
}) => {
  return (
    <Box height='300px' minWidth='100%' maxWidth='100%' marginBottom={'10px'}>
      <StripedDataGrid
        getRowId={getRowId}
        rows={rows}
        columns={columns}
        onRowClick={(params) => setSelectedRow(params.row)}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
          sorting: { sortModel: sortedBy },
        }}
        pageSizeOptions={[1, 5, 10, 15, 20, 25]}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        sx={{
          border: '1px solid black',
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: '900',
            fontSize: '16px',
            color: '#000000',
          },
          '& .MuiDataGrid-main': {
            border: '1px solid black',
          },
          '& .MuiDataGrid-row': {
            borderWidth: '1px 0',
            borderStyle: 'solid',
            borderColor: 'black',
            cursor: 'pointer',
          },
          '& .MuiDataGrid-footerContainer': {
            border: '1px solid black',
          },
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
      />
    </Box>
  );
};
