import { AxiosResponse } from 'axios';
import axios from './axiosConfig';

export interface IndicatorAction {
  indicatorId: number;
  actionId: number;
  indicatorName: string;
  actionName: string;
  isActive: string;
  creationId: number;
}

const createIndicatorAction = async (
  indicatorAction: IndicatorAction
): Promise<AxiosResponse<IndicatorAction>> =>
  axios.post('/indicatorsActions', indicatorAction);
const updateIndicatorAction = async (
  indicatorId: number,
  actionId: number,
  indicatorAction: IndicatorAction
): Promise<AxiosResponse<IndicatorAction>> =>
  axios.patch(`/indicatorsActions/${indicatorId}/${actionId}`, indicatorAction);
const getIndicatorsActions = async (): Promise<
  AxiosResponse<IndicatorAction[]>
> => axios.get('/indicatorsActions');

const indicatorActionApi = {
  createIndicatorAction,
  updateIndicatorAction,
  getIndicatorsActions,
};

export default indicatorActionApi;
