import { AxiosResponse } from 'axios';
import axios from './axiosConfig';

export interface TypeParameter {
  id?: number;
  name: string;
  columnRCMI: string;
  comments?: string;
  isActive: string;
  creationId: number;
}

const createTypeParameter = async (
  typeParameter: TypeParameter
): Promise<AxiosResponse<TypeParameter>> =>
  axios.post('/typeParameters', typeParameter);
const updateTypeParameter = async (
  id: number,
  typeParameter: TypeParameter
): Promise<AxiosResponse<TypeParameter>> =>
  axios.patch(`/typeParameters/${id}`, typeParameter);
const getTypeParameters = async (): Promise<AxiosResponse<TypeParameter[]>> =>
  axios.get('/typeParameters');

const typeParameterApi = {
  createTypeParameter,
  updateTypeParameter,
  getTypeParameters,
};

export default typeParameterApi;
