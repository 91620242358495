import { useState, useCallback } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import typeParameterApi, {
  TypeParameter,
} from '../../../../../api/typeParameter';
import { Form } from './Form';
import { Table } from '../shared/Table';

type Props = {
  action: string;
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'name', headerName: 'Nombre', width: 400 },
  { field: 'columnRCMI', headerName: 'Columna RCMI', width: 400 },
  { field: 'comments', headerName: 'Observaciones', width: 300 },
  {
    field: 'isActive',
    headerName: 'Activo',
    type: 'number',
    width: 90,
  },
];

export const Wrapper: React.FC<Props> = ({ action }) => {
  const [selectedRow, setSelectedRow] = useState<TypeParameter | undefined>();
  const [typeParameters, setTypeParameters] = useState<TypeParameter[] | []>(
    []
  );
  const [tableError, setTableError] = useState<string>('');
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true);

  const fetchTypeParameters = useCallback(async () => {
    setIsTableLoading(true);
    try {
      const responseTypeParameters = await typeParameterApi.getTypeParameters();
      const typeParametersData = responseTypeParameters?.data;
      setTypeParameters(typeParametersData);
    } catch {
      setTableError('Ha ocurrido un error al obtener los tipos de parámetros.');
    }
    setIsTableLoading(false);
  }, []);

  return action === 'add' ? (
    <Form />
  ) : (
    <>
      <Table
        setSelectedRow={setSelectedRow}
        rows={typeParameters}
        columns={columns}
        error={tableError}
        isLoading={isTableLoading}
        setIsTableLoading={setIsTableLoading}
        fetchTable={fetchTypeParameters}
      />
      <Form
        isEditing={action === 'modify'}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        fetchTypeParameters={fetchTypeParameters}
      />
    </>
  );
};
