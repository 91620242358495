import { AxiosResponse } from 'axios';
import axios from './axiosConfig';

export interface Parameter {
  id?: number;
  name: string;
  typeParameterId: number;
  comments?: string;
  isActive: string;
  creationId: number;
}

const createParameter = async (
  parameter: Parameter
): Promise<AxiosResponse<Parameter>> => axios.post('/parameters', parameter);
const updateParameter = async (
  id: number,
  parameter: Parameter
): Promise<AxiosResponse<Parameter>> =>
  axios.patch(`/parameters/${id}`, parameter);
const getParameters = async (): Promise<AxiosResponse<Parameter[]>> =>
  axios.get('/parameters');

const parameterApi = {
  createParameter,
  updateParameter,
  getParameters,
};

export default parameterApi;
