import { useState, useCallback } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import variableApi, { Variable } from '../../../../../api/variable';
import { Form } from './Form';
import { Table } from '../shared/Table';

type Props = {
  action: string;
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'name', headerName: 'Nombre', width: 130 },
  { field: 'description', headerName: 'Descripción', width: 200 },
  { field: 'units', headerName: 'Unidades', width: 130 },
  { field: 'periodicity', headerName: 'Periodicidad', width: 130 },
  { field: 'responsible', headerName: 'Responsable', width: 130 },
  { field: 'source', headerName: 'Fuente', width: 130 },
  { field: 'method', headerName: 'Método', width: 130 },
  { field: 'developmentMethod', headerName: 'Desarrollo Método', width: 130 },
  { field: 'date', headerName: 'Fecha', width: 130 },
  { field: 'origin', headerName: 'Origen', width: 130 },
  { field: 'accuracy', headerName: 'Exactitud', width: 130 },
  { field: 'trust', headerName: 'Confianza', width: 130 },
  {
    field: 'isActive',
    headerName: 'Activo',
    width: 90,
  },
];

export const Wrapper: React.FC<Props> = ({ action }) => {
  const [selectedRow, setSelectedRow] = useState<Variable | undefined>();
  const [variables, setVariables] = useState<Variable[] | []>([]);
  const [tableError, setTableError] = useState<string>('');
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true);

  const fetchVariables = useCallback(async () => {
    setIsTableLoading(true);
    try {
      const responseVariables = await variableApi.getVariables();
      const variablesData = responseVariables?.data;
      setVariables(variablesData);
    } catch {
      setTableError('Ha ocurrido un error al obtener los roles.');
    }
    setIsTableLoading(false);
  }, []);

  return action === 'add' ? (
    <Form />
  ) : (
    <>
      <Table
        setSelectedRow={setSelectedRow}
        rows={variables}
        columns={columns}
        error={tableError}
        isLoading={isTableLoading}
        setIsTableLoading={setIsTableLoading}
        fetchTable={fetchVariables}
      />
      <Form
        isEditing={action === 'modify'}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        fetchVariables={fetchVariables}
      />
    </>
  );
};
