import { useState, useCallback } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import typeMacroProcessApi, {
  TypeMacroProcess,
} from '../../../../../api/typeMacroProcess';
import { Form } from './Form';
import { Table } from '../shared/Table';

type Props = {
  action: string;
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'name', headerName: 'Nombre', width: 400 },
  { field: 'comments', headerName: 'Observaciones', width: 300 },
  {
    field: 'isActive',
    headerName: 'Activo',
    type: 'number',
    width: 90,
  },
];

export const Wrapper: React.FC<Props> = ({ action }) => {
  const [selectedRow, setSelectedRow] = useState<
    TypeMacroProcess | undefined
  >();
  const [typeMacroProcesses, setTypeMacroProcesses] = useState<
    TypeMacroProcess[] | []
  >([]);
  const [tableError, setTableError] = useState<string>('');
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true);

  const fetchTypeMacroProcesses = useCallback(async () => {
    setIsTableLoading(true);
    try {
      const responseTypeMacroProcesses =
        await typeMacroProcessApi.getTypeMacroProcesses();
      const typeMacroProcessesData = responseTypeMacroProcesses?.data;
      setTypeMacroProcesses(typeMacroProcessesData);
    } catch {
      setTableError(
        'Ha ocurrido un error al obtener los tipo de macro procesos.'
      );
    }
    setIsTableLoading(false);
  }, []);

  return action === 'add' ? (
    <Form />
  ) : (
    <>
      <Table
        setSelectedRow={setSelectedRow}
        rows={typeMacroProcesses}
        columns={columns}
        error={tableError}
        isLoading={isTableLoading}
        setIsTableLoading={setIsTableLoading}
        fetchTable={fetchTypeMacroProcesses}
      />
      <Form
        isEditing={action === 'modify'}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        fetchTypeMacroProcesses={fetchTypeMacroProcesses}
      />
    </>
  );
};
