import { Button, ButtonGroup } from '@mui/material';
import { useAuth } from '../../Auth/Auth';

export enum Table {
  ACCIONES = 'Acciones',
  INDICADORES_PR = 'IndicadoresPr',
  INDICADORES_PR_ACCIONES = 'IndicadoresPr Acciones',
  INDICADORES_PR_PROCESOS_MACRO_PROCESOS = 'IndicadoresPr Procesos MacroProcesos',
  INDICADORES_PR_VARIABLES = 'IndicadoresPr Variables',
  MACRO_PROCESOS = 'Macro Procesos',
  PARAMETROS = 'Parámetros',
  PROCESOS = 'Procesos',
  ROLES = 'Roles',
  TIPO_MACRO_PROCESOS = 'Tipo MacroProcesos',
  TIPO_PARAMETROS = 'Tipo Párametros',
  USUARIOS = 'Usuarios',
  VARIABLES = 'Variables',
}

type Props = {
  activeMenu: Table;
  setActiveMenu: React.Dispatch<React.SetStateAction<Table>>;
};

const tables: { table: Table; disabled: boolean; onlyAdmin: boolean }[] = [
  { table: Table.ACCIONES, disabled: false, onlyAdmin: false },
  { table: Table.INDICADORES_PR, disabled: false, onlyAdmin: false },
  { table: Table.INDICADORES_PR_ACCIONES, disabled: false, onlyAdmin: false },
  {
    table: Table.INDICADORES_PR_PROCESOS_MACRO_PROCESOS,
    disabled: false,
    onlyAdmin: false,
  },
  { table: Table.INDICADORES_PR_VARIABLES, disabled: false, onlyAdmin: false },
  { table: Table.MACRO_PROCESOS, disabled: false, onlyAdmin: false },
  { table: Table.PARAMETROS, disabled: false, onlyAdmin: false },
  { table: Table.PROCESOS, disabled: false, onlyAdmin: false },
  { table: Table.ROLES, disabled: false, onlyAdmin: true },
  { table: Table.TIPO_MACRO_PROCESOS, disabled: false, onlyAdmin: false },
  { table: Table.TIPO_PARAMETROS, disabled: false, onlyAdmin: false },
  { table: Table.USUARIOS, disabled: false, onlyAdmin: true },
  { table: Table.VARIABLES, disabled: false, onlyAdmin: false },
];

export const Menu: React.FC<Props> = ({ activeMenu, setActiveMenu }) => {
  const auth = useAuth();

  return (
    <ButtonGroup
      orientation='vertical'
      aria-label='vertical contained button group'
      variant='contained'
      size='large'
      color='secondary'
    >
      {tables.map(({ table, disabled, onlyAdmin }) =>
        onlyAdmin ? (
          auth?.isAdmin && (
            <Button
              disabled={disabled}
              key={table}
              onClick={() => setActiveMenu(table)}
              sx={{
                paddingY: '14px',
                textTransform: 'none',
                backgroundColor: activeMenu === table ? '#255AA3' : '#67686B',
              }}
            >
              {table}
            </Button>
          )
        ) : (
          <Button
            disabled={disabled}
            key={table}
            onClick={() => setActiveMenu(table)}
            sx={{
              paddingY: '14px',
              textTransform: 'none',
              backgroundColor: activeMenu === table ? '#255AA3' : '#67686B',
            }}
          >
            {table}
          </Button>
        )
      )}
    </ButtonGroup>
  );
};
