import { useState, useCallback } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import indicatorApi, { Indicator } from '../../../../../api/indicator';
import { Form } from './Form';
import { Table } from '../shared/Table';

type Props = {
  action: string;
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  {
    field: 'parentId',
    headerName: 'ID Padre',
    width: 70,
  },
  { field: 'name', headerName: 'Nombre', width: 130 },
  { field: 'target', headerName: 'Objetivo', width: 130 },
  { field: 'formula', headerName: 'Fórmula', width: 130 },
  { field: 'udFormula', headerName: 'UD Fórmula', width: 130 },
  { field: 'periodicity', headerName: 'Periodicidad', width: 130 },
  { field: 'responsible', headerName: 'Responsable', width: 130 },
  { field: 'lowerLimit', headerName: 'Límite Inferior', width: 130 },
  { field: 'upperLimit', headerName: 'Límite Superior', width: 130 },
  { field: 'targetValue', headerName: 'Valor Objetivo', width: 130 },
  { field: 'settingDate', headerName: 'Fecha de Parametrización', width: 130 },
  { field: 'startValue', headerName: 'Star Value', width: 130 },
  { field: 'endValue', headerName: 'End Value', width: 130 },
  { field: 'indicatorWeight', headerName: 'Peso Indicador', width: 130 },
  {
    field: 'priorityIndicator',
    headerName: 'Indicador Prioritario',
    width: 50,
  },
  { field: 'validityPeriod', headerName: 'Período de Validez', width: 130 },
  { field: 'children', headerName: 'Hijos', width: 130 },
  { field: 'comments', headerName: 'Observaciones', width: 200 },
  {
    field: 'isActive',
    headerName: 'Activo',
    width: 90,
  },
];

export const Wrapper: React.FC<Props> = ({ action }) => {
  const [selectedRow, setSelectedRow] = useState<Indicator | undefined>();
  const [indicators, setIndicators] = useState<Indicator[] | []>([]);
  const [tableError, setTableError] = useState<string>('');
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true);

  const fetchIndicators = useCallback(async () => {
    setIsTableLoading(true);
    try {
      const responseIndicators = await indicatorApi.getIndicators();
      const indicatorData = responseIndicators?.data;
      setIndicators(indicatorData);
    } catch {
      setTableError('Ha ocurrido un error al obtener los indicadores.');
    }
    setIsTableLoading(false);
  }, []);

  return action === 'add' ? (
    <Form />
  ) : (
    <>
      <Table
        setSelectedRow={setSelectedRow}
        rows={indicators}
        columns={columns}
        error={tableError}
        isLoading={isTableLoading}
        setIsTableLoading={setIsTableLoading}
        fetchTable={fetchIndicators}
      />
      <Form
        isEditing={action === 'modify'}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        fetchIndicators={fetchIndicators}
      />
    </>
  );
};
