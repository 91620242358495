import { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
  Typography,
} from '@mui/material';
import indicatorActionApi, {
  IndicatorAction,
} from '../../../../../api/indicatorAction';
import indicatorApi, { Indicator } from '../../../../../api/indicator';
import actionApi, { Action } from '../../../../../api/action';
import { useAuth } from '../../../../../Auth/Auth';
import { filterOnlyActive } from '../utils';
import { sortIndicatorByName } from '../IndicadoresPr/utils';
import { sortActionByName } from '../Acciones/utils';

type Props = {
  isEditing?: boolean;
  selectedRow?: IndicatorAction;
  setSelectedRow?: React.Dispatch<
    React.SetStateAction<IndicatorAction | undefined>
  >;
  fetchIndicatorsActions?: () => Promise<void>;
};

const DEFAULT_INDICATOR_ID = 0;
const DEFAULT_ACTION_ID = 0;
const DEFAULT_USER_ID = 1;

export const Form: React.FC<Props> = ({
  isEditing = false,
  selectedRow,
  setSelectedRow,
  fetchIndicatorsActions,
}) => {
  const auth = useAuth();
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ok, setOk] = useState<boolean>(false);
  const [indicatorAction, setIndicatorAction] = useState<IndicatorAction>({
    indicatorId: DEFAULT_INDICATOR_ID,
    actionId: DEFAULT_ACTION_ID,
    indicatorName: '',
    actionName: '',
    isActive: 'SI',
    creationId: auth?.user?.id || DEFAULT_USER_ID,
  });
  const [indicators, setIndicators] = useState<Indicator[] | []>([]);
  const [indicatorsError, setIndicatorsError] = useState<string>('');
  const [isIndicatorsLoading, setIsIndicatorsLoading] = useState<boolean>(true);
  const [actions, setActions] = useState<Action[] | []>([]);
  const [actionsError, setActionsError] = useState<string>('');
  const [isActionsLoading, setIsActionsLoading] = useState<boolean>(true);

  const fetchIndicators = useCallback(async () => {
    setIsIndicatorsLoading(true);
    try {
      const responseIndicators = await indicatorApi.getIndicators();
      const indicatorsData = responseIndicators?.data ?? [];
      setIndicators(filterOnlyActive(sortIndicatorByName(indicatorsData)));
    } catch {
      setIndicatorsError('Ha ocurrido un error al obtener los indicadores.');
    }
    setIsIndicatorsLoading(false);
  }, []);

  const fetchActions = useCallback(async () => {
    setIsActionsLoading(true);
    try {
      const responseActions = await actionApi.getActions();
      const actionsData = responseActions?.data ?? [];
      setActions(filterOnlyActive(sortActionByName(actionsData)));
    } catch {
      setActionsError('Ha ocurrido un error al obtener las acciones.');
    }
    setIsActionsLoading(false);
  }, []);

  useEffect(() => {
    fetchIndicators();
    fetchActions();
    if (isEditing && setSelectedRow) {
      if (selectedRow) {
        setIndicatorAction({
          indicatorId: selectedRow.indicatorId,
          actionId: selectedRow.actionId,
          indicatorName: selectedRow.indicatorName,
          actionName: selectedRow.actionName,
          isActive: selectedRow.isActive,
          creationId: auth?.user?.id || DEFAULT_USER_ID,
        });
      }
    }
  }, [
    auth?.user?.id,
    fetchIndicators,
    fetchActions,
    isEditing,
    selectedRow,
    setSelectedRow,
  ]);

  const handleOnClickSave = useCallback(async () => {
    setError('');
    setIndicatorsError('');
    setActionsError('');
    const indicatorId = indicatorAction.indicatorId;
    const actionId = indicatorAction.actionId;
    setIsLoading(true);
    try {
      if (indicatorId && actionId && isEditing) {
        await indicatorActionApi.updateIndicatorAction(
          selectedRow!.indicatorId,
          selectedRow!.actionId,
          indicatorAction
        );
      } else {
        await indicatorActionApi.createIndicatorAction(indicatorAction);
      }
      setOk(true);
    } catch {
      setError('Ha ocurrido un error al guardar el indicador acción.');
    }
    setIndicatorAction({
      indicatorId: DEFAULT_INDICATOR_ID,
      actionId: DEFAULT_ACTION_ID,
      indicatorName: '',
      actionName: '',
      isActive: 'SI',
      creationId: auth?.user?.id || DEFAULT_USER_ID,
    });
    if (isEditing && setSelectedRow) {
      setSelectedRow(undefined);
    }
    setIsLoading(false);
    if (fetchIndicatorsActions) {
      await fetchIndicatorsActions();
    }
  }, [
    indicatorAction,
    auth?.user?.id,
    isEditing,
    setSelectedRow,
    fetchIndicatorsActions,
    selectedRow,
  ]);

  const isIndicatorsIdInError =
    isEditing &&
    !indicators.some(
      (indicator) => indicator?.id === indicatorAction.indicatorId
    );

  const isActionsIdInError =
    isEditing &&
    !actions.some((action) => action?.id === indicatorAction.actionId);

  return (
    <>
      <Box
        component='form'
        sx={{
          '& .MuiTextField-root': { m: 1, width: '40ch' },
        }}
        noValidate
        autoComplete='off'
      >
        <TextField
          select
          required
          label='Nombre Indicador Proceso'
          helperText='Nombre Indicador Proceso'
          value={
            !indicators.length ||
            !indicatorAction.indicatorId ||
            isIndicatorsIdInError
              ? ''
              : indicatorAction.indicatorId
          }
          onChange={(e) =>
            setIndicatorAction((prevState) => ({
              ...prevState,
              indicatorId: e.target.value as unknown as number,
              indicatorName:
                indicators.find(
                  (indicator) =>
                    indicator.id === (e.target.value as unknown as number)
                )?.name ?? '',
            }))
          }
          error={!!!indicatorAction.indicatorId || isIndicatorsIdInError}
        >
          {indicators.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          required
          label='Nombre Acción'
          helperText='Nombre Acción'
          value={
            !actions.length || !indicatorAction.actionId || isActionsIdInError
              ? ''
              : indicatorAction.actionId
          }
          onChange={(e) =>
            setIndicatorAction((prevState) => ({
              ...prevState,
              actionId: e.target.value as unknown as number,
              actionName:
                actions.find(
                  (action) =>
                    action.id === (e.target.value as unknown as number)
                )?.name ?? '',
            }))
          }
          error={!!!indicatorAction.actionId || isActionsIdInError}
        >
          {actions.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        {isEditing && (
          <TextField
            select
            required
            label='Activo'
            value={indicatorAction.isActive}
            onChange={(e) =>
              setIndicatorAction((prevState) => ({
                ...prevState,
                isActive: e.target.value,
              }))
            }
          >
            {[
              { value: 'SI', label: 'SI' },
              { value: 'NO', label: 'NO' },
            ].map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Box>
      <Button
        variant='contained'
        sx={{ textTransform: 'none' }}
        onClick={handleOnClickSave}
        disabled={
          isLoading ||
          isIndicatorsLoading ||
          isActionsLoading ||
          !!!indicatorAction.indicatorId ||
          !!!indicatorAction.actionId ||
          !!!indicatorAction.indicatorName ||
          !!!indicatorAction.actionName ||
          !!!indicatorAction.isActive ||
          isIndicatorsIdInError ||
          isActionsIdInError ||
          (isEditing && !selectedRow)
        }
      >
        {isLoading || isIndicatorsLoading || isActionsLoading ? (
          <CircularProgress size={25} />
        ) : (
          'Guardar'
        )}
      </Button>
      {(error || indicatorsError || actionsError) && (
        <Typography color='red'>
          {error || indicatorsError || actionsError}
        </Typography>
      )}
      {ok && (
        <Typography marginTop={'10px'}>
          El indicador acción se ha guardado correctamente.
        </Typography>
      )}
    </>
  );
};
