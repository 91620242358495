import { AxiosResponse } from 'axios';
import axios from './axiosConfig';

export interface Process {
  id?: number;
  macroProcessId: number;
  name: string;
  description?: string;
  priority?: number | null;
  weight?: number | null;
  risks?: string;
  metaNumerics: number;
  prResult?: number | null;
  lowerLimit: number;
  upperLimit: number;
  startValue: number;
  endValue: number;
  settingDate?: string | null;
  validityPeriod?: string;
  comments?: string;
  isActive: string;
  creationId: number;
}

const createProcess = async (
  process: Process
): Promise<AxiosResponse<Process>> => axios.post('/processes', process);
const updateProcess = async (
  id: number,
  process: Process
): Promise<AxiosResponse<Process>> => axios.patch(`/processes/${id}`, process);
const getProcesses = async (): Promise<AxiosResponse<Process[]>> =>
  axios.get('/processes');

const processApi = {
  createProcess,
  updateProcess,
  getProcesses,
};

export default processApi;
