import { useState } from 'react';
import { Stack, useMediaQuery, Typography } from '@mui/material';
import { Menu, Table } from './components/Menu';
import { Dashboard } from './components/Dashboard/Dashboard';

export const AdminPage: React.FC = () => {
  const isMobile = useMediaQuery('(max-width:1024px)');
  const [activeMenu, setActiveMenu] = useState(Table.ACCIONES);

  return (
    <Stack direction='row'>
      {isMobile ? (
        <Typography variant='h6' padding='30px'>
          Ésta aplicación no es compatible con resoluciones menores a 1024 pixeles. Por
          favor ingrese desde otro dispositivo.
        </Typography>
      ) : (
        <>
          <Menu activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
          <Dashboard activeMenu={activeMenu} />
        </>
      )}
    </Stack>
  );
};
