import { AxiosResponse } from 'axios';
import { sha256 } from 'js-sha256';
import axios from './axiosConfig';
import { AuthUser } from '../Auth/Auth';

const performLogin = async (credentials: {
  username: string;
  password: string;
}): Promise<AxiosResponse<{ access_token: string }>> =>
  axios.post('/login', {
    email: credentials.username,
    password: sha256(credentials.password)?.toUpperCase(),
  });
const getProfile = async (): Promise<AxiosResponse<AuthUser>> =>
  axios.get('/users/me');

const authApi = { performLogin, getProfile };

export default authApi;
