import { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
  Typography,
} from '@mui/material';
import indicatorVariableApi, {
  IndicatorVariable,
} from '../../../../../api/indicatorVariable';
import indicatorApi, { Indicator } from '../../../../../api/indicator';
import variableApi, { Variable } from '../../../../../api/variable';
import { useAuth } from '../../../../../Auth/Auth';
import { filterOnlyActive } from '../utils';
import { sortIndicatorByName } from '../IndicadoresPr/utils';
import { sortVariableByName } from '../Variables/utils';

type Props = {
  isEditing?: boolean;
  selectedRow?: IndicatorVariable;
  setSelectedRow?: React.Dispatch<
    React.SetStateAction<IndicatorVariable | undefined>
  >;
  fetchIndicatorsVariables?: () => Promise<void>;
};

const DEFAULT_INDICATOR_ID = 0;
const DEFAULT_VARIABLE_ID = 0;
const DEFAULT_USER_ID = 1;

export const Form: React.FC<Props> = ({
  isEditing = false,
  selectedRow,
  setSelectedRow,
  fetchIndicatorsVariables,
}) => {
  const auth = useAuth();
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ok, setOk] = useState<boolean>(false);
  const [indicatorVariable, setIndicatorVariable] = useState<IndicatorVariable>(
    {
      indicatorId: DEFAULT_INDICATOR_ID,
      variableId: DEFAULT_VARIABLE_ID,
      indicatorName: '',
      variableName: '',
      isActive: 'SI',
      creationId: auth?.user?.id || DEFAULT_USER_ID,
    }
  );
  const [indicators, setIndicators] = useState<Indicator[] | []>([]);
  const [indicatorsError, setIndicatorsError] = useState<string>('');
  const [isIndicatorsLoading, setIsIndicatorsLoading] = useState<boolean>(true);
  const [variables, setVariables] = useState<Variable[] | []>([]);
  const [variablesError, setVariablesError] = useState<string>('');
  const [isVariablesLoading, setIsVariablesLoading] = useState<boolean>(true);

  const fetchIndicators = useCallback(async () => {
    setIsIndicatorsLoading(true);
    try {
      const responseIndicators = await indicatorApi.getIndicators();
      const indicatorsData = responseIndicators?.data ?? [];
      setIndicators(filterOnlyActive(sortIndicatorByName(indicatorsData)));
    } catch {
      setIndicatorsError('Ha ocurrido un error al obtener los indicadores.');
    }
    setIsIndicatorsLoading(false);
  }, []);

  const fetchVariables = useCallback(async () => {
    setIsVariablesLoading(true);
    try {
      const responseVariables = await variableApi.getVariables();
      const variablesData = responseVariables?.data ?? [];
      setVariables(filterOnlyActive(sortVariableByName(variablesData)));
    } catch {
      setVariablesError('Ha ocurrido un error al obtener las variables.');
    }
    setIsVariablesLoading(false);
  }, []);

  useEffect(() => {
    fetchIndicators();
    fetchVariables();
    if (isEditing && setSelectedRow) {
      if (selectedRow) {
        setIndicatorVariable({
          indicatorId: selectedRow.indicatorId,
          variableId: selectedRow.variableId,
          indicatorName: selectedRow.indicatorName,
          variableName: selectedRow.variableName,
          isActive: selectedRow.isActive,
          creationId: auth?.user?.id || DEFAULT_USER_ID,
        });
      }
    }
  }, [
    auth?.user?.id,
    fetchIndicators,
    fetchVariables,
    isEditing,
    selectedRow,
    setSelectedRow,
  ]);

  const handleOnClickSave = useCallback(async () => {
    setError('');
    setIndicatorsError('');
    setVariablesError('');
    const indicatorId = indicatorVariable.indicatorId;
    const variableId = indicatorVariable.variableId;
    setIsLoading(true);
    try {
      if (indicatorId && variableId && isEditing) {
        await indicatorVariableApi.updateIndicatorVariable(
          selectedRow!.indicatorId,
          selectedRow!.variableId,
          indicatorVariable
        );
      } else {
        await indicatorVariableApi.createIndicatorVariable(indicatorVariable);
      }
      setOk(true);
    } catch {
      setError('Ha ocurrido un error al guardar el indicador variable.');
    }
    setIndicatorVariable({
      indicatorId: DEFAULT_INDICATOR_ID,
      variableId: DEFAULT_VARIABLE_ID,
      indicatorName: '',
      variableName: '',
      isActive: 'SI',
      creationId: auth?.user?.id || DEFAULT_USER_ID,
    });
    if (isEditing && setSelectedRow) {
      setSelectedRow(undefined);
    }
    setIsLoading(false);
    if (fetchIndicatorsVariables) {
      await fetchIndicatorsVariables();
    }
  }, [
    indicatorVariable,
    auth?.user?.id,
    isEditing,
    setSelectedRow,
    fetchIndicatorsVariables,
    selectedRow,
  ]);

  const isIndicatorsIdInError =
    isEditing &&
    !indicators.some(
      (indicator) => indicator?.id === indicatorVariable.indicatorId
    );

  const isVariablesIdInError =
    isEditing &&
    !variables.some(
      (variable) => variable?.id === indicatorVariable.variableId
    );

  return (
    <>
      <Box
        component='form'
        sx={{
          '& .MuiTextField-root': { m: 1, width: '40ch' },
        }}
        noValidate
        autoComplete='off'
      >
        <TextField
          select
          required
          label='Nombre Indicador Proceso'
          helperText='Nombre Indicador Proceso'
          value={
            !indicators.length ||
            !indicatorVariable.indicatorId ||
            isIndicatorsIdInError
              ? ''
              : indicatorVariable.indicatorId
          }
          onChange={(e) =>
            setIndicatorVariable((prevState) => ({
              ...prevState,
              indicatorId: e.target.value as unknown as number,
              indicatorName:
                indicators.find(
                  (indicator) =>
                    indicator.id === (e.target.value as unknown as number)
                )?.name ?? '',
            }))
          }
          error={!!!indicatorVariable.indicatorId || isIndicatorsIdInError}
        >
          {indicators.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          required
          label='Nombre Variable'
          helperText='Nombre Variable'
          value={
            !variables.length ||
            !indicatorVariable.variableId ||
            isVariablesIdInError
              ? ''
              : indicatorVariable.variableId
          }
          onChange={(e) =>
            setIndicatorVariable((prevState) => ({
              ...prevState,
              variableId: e.target.value as unknown as number,
              variableName:
                variables.find(
                  (variable) =>
                    variable.id === (e.target.value as unknown as number)
                )?.name ?? '',
            }))
          }
          error={!!!indicatorVariable.variableId || isVariablesIdInError}
        >
          {variables.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        {isEditing && (
          <TextField
            select
            required
            label='Activo'
            value={indicatorVariable.isActive}
            onChange={(e) =>
              setIndicatorVariable((prevState) => ({
                ...prevState,
                isActive: e.target.value,
              }))
            }
          >
            {[
              { value: 'SI', label: 'SI' },
              { value: 'NO', label: 'NO' },
            ].map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Box>
      <Button
        variant='contained'
        sx={{ textTransform: 'none' }}
        onClick={handleOnClickSave}
        disabled={
          isLoading ||
          isIndicatorsLoading ||
          isVariablesLoading ||
          !!!indicatorVariable.indicatorId ||
          !!!indicatorVariable.variableId ||
          !!!indicatorVariable.indicatorName ||
          !!!indicatorVariable.variableName ||
          !!!indicatorVariable.isActive ||
          isIndicatorsIdInError ||
          isVariablesIdInError ||
          (isEditing && !selectedRow)
        }
      >
        {isLoading || isIndicatorsLoading || isVariablesLoading ? (
          <CircularProgress size={25} />
        ) : (
          'Guardar'
        )}
      </Button>
      {(error || indicatorsError || variablesError) && (
        <Typography color='red'>
          {error || indicatorsError || variablesError}
        </Typography>
      )}
      {ok && (
        <Typography marginTop={'10px'}>
          El indicador variable se ha guardado correctamente.
        </Typography>
      )}
    </>
  );
};
