/* eslint-disable no-useless-concat */
import { Role } from '../../../../../api/role';

export const generatePassword = () => {
  let pass = '';
  const str =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZ' + 'abcdefghijklmnopqrstuvwxyz0123456789@#$';

  for (let i = 1; i <= 8; i++) {
    const char = Math.floor(Math.random() * str.length + 1);

    pass += str.charAt(char);
  }

  return pass;
};

export const sortRolesByName = (roles: Role[]): Role[] =>
  roles.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  });

export const isValidEmail = (email: string) =>
  !!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
