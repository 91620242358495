import { AxiosResponse } from 'axios';
import { sha256 } from 'js-sha256';
import axios from './axiosConfig';

export interface User {
  id?: number;
  name: string;
  firstLastname: string;
  secondLastname?: string;
  email: string;
  address?: string;
  telephone?: string;
  roleId: number;
  password: string;
  isActive: string;
  creationId: number;
}

const createUser = async (user: User): Promise<AxiosResponse<User>> =>
  axios.post('/users', {
    ...user,
    password: sha256(user.password)?.toUpperCase(),
  });
const updateUser = async (
  id: number,
  user: User,
  passwordWasChanged: boolean
): Promise<AxiosResponse<User>> =>
  axios.patch(`/users/${id}`, {
    ...user,
    password: passwordWasChanged
      ? sha256(user.password)?.toUpperCase()
      : user.password,
  });
const getUsers = async (): Promise<AxiosResponse<User[]>> =>
  axios.get('/users');

const userApi = { createUser, updateUser, getUsers };

export default userApi;
