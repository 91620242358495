import { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
  Typography,
} from '@mui/material';
import parameterApi, { Parameter } from '../../../../../api/parameter';
import typeParameterApi, {
  TypeParameter,
} from '../../../../../api/typeParameter';
import { useAuth } from '../../../../../Auth/Auth';
import { sortTypeParametersByName } from './utils';
import { filterOnlyActive } from '../utils';

type Props = {
  isEditing?: boolean;
  selectedRow?: Parameter;
  setSelectedRow?: React.Dispatch<React.SetStateAction<Parameter | undefined>>;
  fetchParameters?: () => Promise<void>;
};

const DEFAULT_TYPE_PARAMETER_ID = 0;
const DEFAULT_USER_ID = 1;

export const Form: React.FC<Props> = ({
  isEditing = false,
  selectedRow,
  setSelectedRow,
  fetchParameters,
}) => {
  const auth = useAuth();
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ok, setOk] = useState<boolean>(false);
  const [parameter, setParameter] = useState<Parameter>({
    name: '',
    typeParameterId: DEFAULT_TYPE_PARAMETER_ID,
    comments: '',
    isActive: 'SI',
    creationId: auth?.user?.id || DEFAULT_USER_ID,
  });
  const [typeParameters, setTypeParameters] = useState<TypeParameter[] | []>(
    []
  );
  const [typeParametersError, setTypeParametersError] = useState<string>('');
  const [isTypeParametersLoading, setIsTypeParametersLoading] =
    useState<boolean>(true);

  const fetchTypeParameters = useCallback(async () => {
    setIsTypeParametersLoading(true);
    try {
      const responseTypeParameters = await typeParameterApi.getTypeParameters();
      const typeParametersData = responseTypeParameters?.data ?? [];
      setTypeParameters(
        filterOnlyActive(sortTypeParametersByName(typeParametersData))
      );
    } catch {
      setTypeParametersError('Ha ocurrido un error al obtener los parámetros.');
    }
    setIsTypeParametersLoading(false);
  }, []);

  useEffect(() => {
    fetchTypeParameters();
    if (isEditing && setSelectedRow) {
      setSelectedRow(undefined);
      if (selectedRow) {
        setParameter({
          id: selectedRow.id,
          name: selectedRow.name,
          typeParameterId: selectedRow.typeParameterId,
          comments: selectedRow.comments ?? '',
          isActive: selectedRow.isActive,
          creationId: auth?.user?.id || DEFAULT_USER_ID,
        });
      }
    }
  }, [
    auth?.user?.id,
    fetchTypeParameters,
    isEditing,
    selectedRow,
    setSelectedRow,
  ]);

  const handleOnClickSave = useCallback(async () => {
    setError('');
    setTypeParametersError('');
    const typeParameterId = parameter.id;
    setIsLoading(true);
    try {
      if (typeParameterId) {
        await parameterApi.updateParameter(typeParameterId, parameter);
      } else {
        await parameterApi.createParameter(parameter);
      }
      setOk(true);
    } catch {
      setError('Ha ocurrido un error al guardar el parámetro.');
    }
    setParameter({
      name: '',
      typeParameterId: DEFAULT_TYPE_PARAMETER_ID,
      comments: '',
      isActive: 'SI',
      creationId: auth?.user?.id || DEFAULT_USER_ID,
    });
    if (isEditing && setSelectedRow) {
      setSelectedRow(undefined);
    }
    setIsLoading(false);
    if (fetchParameters) {
      await fetchParameters();
    }
  }, [parameter, auth?.user?.id, isEditing, setSelectedRow, fetchParameters]);

  const isTypeParameterIdInError =
    isEditing &&
    !typeParameters.some(
      (typeParameter) => typeParameter?.id === parameter.typeParameterId
    );

  return (
    <>
      <Box
        component='form'
        sx={{
          '& .MuiTextField-root': { m: 1, width: '40ch' },
        }}
        noValidate
        autoComplete='off'
      >
        <TextField
          required
          label='Nombre'
          helperText='Nombre'
          value={parameter.name}
          onChange={(e) =>
            setParameter((prevState) => ({
              ...prevState,
              name: e.target.value,
            }))
          }
          error={!!!parameter.name}
          inputProps={{ maxLength: 200 }}
        />
        <TextField
          select
          required
          label='Tipo de Parámetro'
          helperText='Tipo de Parámetro'
          value={
            !typeParameters.length ||
            !parameter.typeParameterId ||
            isTypeParameterIdInError
              ? ''
              : parameter.typeParameterId
          }
          onChange={(e) =>
            setParameter((prevState) => ({
              ...prevState,
              typeParameterId: e.target.value as unknown as number,
            }))
          }
          error={!!!parameter.typeParameterId || isTypeParameterIdInError}
        >
          {typeParameters.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          type=''
          label='Observaciones'
          helperText='Observaciones'
          value={parameter.comments}
          onChange={(e) =>
            setParameter((prevState) => ({
              ...prevState,
              comments: e.target.value,
            }))
          }
        />
        {isEditing && (
          <TextField
            select
            required
            label='Activo'
            value={parameter.isActive}
            onChange={(e) =>
              setParameter((prevState) => ({
                ...prevState,
                isActive: e.target.value,
              }))
            }
          >
            {[
              { value: 'SI', label: 'SI' },
              { value: 'NO', label: 'NO' },
            ].map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Box>
      <Button
        variant='contained'
        sx={{ textTransform: 'none' }}
        onClick={handleOnClickSave}
        disabled={
          isLoading ||
          isTypeParametersLoading ||
          !!!parameter.name ||
          !!!parameter.typeParameterId ||
          isTypeParameterIdInError ||
          !!!parameter.isActive ||
          (isEditing && !!!parameter.id)
        }
      >
        {isLoading || isTypeParametersLoading ? (
          <CircularProgress size={25} />
        ) : (
          'Guardar'
        )}
      </Button>
      {(error || typeParametersError) && (
        <Typography color='red'>{error || typeParametersError}</Typography>
      )}
      {ok && (
        <Typography marginTop={'10px'}>
          El parámetro se ha guardado correctamente.
        </Typography>
      )}
    </>
  );
};
