import { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
  Typography,
} from '@mui/material';
import roleApi, { Role } from '../../../../../api/role';
import { useAuth } from '../../../../../Auth/Auth';

type Props = {
  isEditing?: boolean;
  selectedRow?: Role;
  setSelectedRow?: React.Dispatch<React.SetStateAction<Role | undefined>>;
  fetchRoles?: () => Promise<void>;
};

const DEFAULT_USER_ID = 1;

export const Form: React.FC<Props> = ({
  isEditing = false,
  selectedRow,
  setSelectedRow,
  fetchRoles,
}) => {
  const auth = useAuth();
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ok, setOk] = useState<boolean>(false);
  const [role, setRole] = useState<Role>({
    name: '',
    description: '',
    isActive: 'SI',
    creationId: auth?.user?.id || DEFAULT_USER_ID,
  });

  useEffect(() => {
    if (isEditing && setSelectedRow) {
      setSelectedRow(undefined);
      if (selectedRow) {
        setRole({
          id: selectedRow.id,
          name: selectedRow.name,
          description: selectedRow.description,
          isActive: selectedRow.isActive,
          creationId: auth?.user?.id || DEFAULT_USER_ID,
        });
      }
    }
  }, [auth?.user?.id, isEditing, selectedRow, setSelectedRow]);

  const handleOnClickSave = useCallback(async () => {
    setError('');
    const roleId = role.id;
    setIsLoading(true);
    try {
      if (roleId) {
        await roleApi.updateRole(roleId, role);
      } else {
        await roleApi.createRole(role);
      }
      setOk(true);
    } catch {
      setError('Ha ocurrido un error al guardar el rol.');
    }
    setRole({
      name: '',
      description: '',
      isActive: 'SI',
      creationId: auth?.user?.id || DEFAULT_USER_ID,
    });
    if (isEditing && setSelectedRow) {
      setSelectedRow(undefined);
    }
    setIsLoading(false);
    if (fetchRoles) {
      await fetchRoles();
    }
  }, [auth?.user?.id, fetchRoles, isEditing, role, setSelectedRow]);

  return (
    <>
      <Box
        component='form'
        sx={{
          '& .MuiTextField-root': { m: 1, width: '40ch' },
        }}
        noValidate
        autoComplete='off'
      >
        <TextField
          required
          label='Nombre'
          helperText='Nombre del Rol'
          value={role.name}
          onChange={(e) =>
            setRole((prevState) => ({ ...prevState, name: e.target.value }))
          }
          error={!!!role.name}
          inputProps={{ maxLength: 50 }}
        />
        <TextField
          label='Descripción'
          helperText='Descripción del Rol'
          value={role.description ?? ''}
          onChange={(e) =>
            setRole((prevState) => ({
              ...prevState,
              description: e.target.value,
            }))
          }
        />
        {isEditing && (
          <TextField
            select
            required
            label='Activo'
            value={role.isActive}
            onChange={(e) =>
              setRole((prevState) => ({
                ...prevState,
                isActive: e.target.value,
              }))
            }
          >
            {[
              { value: 'SI', label: 'SI' },
              { value: 'NO', label: 'NO' },
            ].map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Box>
      <Button
        variant='contained'
        sx={{ textTransform: 'none' }}
        onClick={handleOnClickSave}
        disabled={
          isLoading ||
          !!!role.name ||
          !!!role.isActive ||
          (isEditing && !!!role.id)
        }
      >
        {isLoading ? <CircularProgress size={25} /> : 'Guardar'}
      </Button>
      {error && <Typography color='red'>{error}</Typography>}
      {ok && (
        <Typography marginTop={'10px'}>
          El rol se ha guardado correctamente.
        </Typography>
      )}
    </>
  );
};
