import { AxiosResponse } from 'axios';
import axios from './axiosConfig';

export interface Variable {
  id?: number;
  name: string;
  description?: string;
  units: string;
  periodicity: string;
  responsible: string;
  source: string;
  method: string;
  developmentMethod?: string;
  date?: string | null;
  origin: string;
  accuracy: string;
  trust: string;
  isActive: string;
  creationId: number;
}

const createVariable = async (
  variable: Variable
): Promise<AxiosResponse<Variable>> => axios.post('/variables', variable);
const updateVariable = async (
  id: number,
  variable: Variable
): Promise<AxiosResponse<Variable>> =>
  axios.patch(`/variables/${id}`, variable);
const getVariables = async (): Promise<AxiosResponse<Variable[]>> =>
  axios.get('/variables');

const variableApi = {
  createVariable,
  updateVariable,
  getVariables,
};

export default variableApi;
