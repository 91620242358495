import { useState, useCallback } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import indicatorVariableApi, {
  IndicatorVariable,
} from '../../../../../api/indicatorVariable';
import { Form } from './Form';
import { Table } from '../shared/Table';

type Props = {
  action: string;
};

const columns: GridColDef[] = [
  { field: 'indicatorId', headerName: 'ID Indicador Proceso', width: 100 },
  { field: 'variableId', headerName: 'ID Variable', width: 100 },
  {
    field: 'indicatorName',
    headerName: 'Nombre Indicador Proceso',
    width: 300,
  },
  { field: 'variableName', headerName: 'Nombre Variable', width: 300 },
  {
    field: 'isActive',
    headerName: 'Activo',
    width: 90,
  },
];

export const Wrapper: React.FC<Props> = ({ action }) => {
  const [selectedRow, setSelectedRow] = useState<
    IndicatorVariable | undefined
  >();
  const [indicatorsVariables, setIndicatorsVariables] = useState<
    IndicatorVariable[] | []
  >([]);
  const [tableError, setTableError] = useState<string>('');
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true);

  const fetchIndicatorsVariables = useCallback(async () => {
    setIsTableLoading(true);
    try {
      const responseIndicatorsVariables =
        await indicatorVariableApi.getIndicatorsVariables();
      const indicatorsVariablesData = responseIndicatorsVariables?.data;
      setIndicatorsVariables(indicatorsVariablesData);
    } catch {
      setTableError(
        'Ha ocurrido un error al obtener los indicadores variables.'
      );
    }
    setIsTableLoading(false);
  }, []);

  return action === 'add' ? (
    <Form />
  ) : (
    <>
      <Table
        getRowId={(row) => `${row.indicatorId}-${row.variableId}`}
        setSelectedRow={setSelectedRow}
        rows={indicatorsVariables}
        columns={columns}
        error={tableError}
        isLoading={isTableLoading}
        setIsTableLoading={setIsTableLoading}
        fetchTable={fetchIndicatorsVariables}
      />
      <Form
        isEditing={action === 'modify'}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        fetchIndicatorsVariables={fetchIndicatorsVariables}
      />
    </>
  );
};
