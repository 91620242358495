import { ViewWrapper } from '../shared/ViewWrapper';
import { Table as TableEnum } from '../../../Menu';
import { Wrapper } from './Wrapper';

type Props = {
  activeMenu: TableEnum;
};

export const IndicadoresPrProcesosMacroProcesos: React.FC<Props> = ({
  activeMenu,
}) => <ViewWrapper activeMenu={activeMenu} Wrapper={Wrapper} />;
