import { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import variableApi, { Variable } from '../../../../../api/variable';
import { useAuth } from '../../../../../Auth/Auth';

type Props = {
  isEditing?: boolean;
  selectedRow?: Variable;
  setSelectedRow?: React.Dispatch<React.SetStateAction<Variable | undefined>>;
  fetchVariables?: () => Promise<void>;
};

const DEFAULT_USER_ID = 1;

export const Form: React.FC<Props> = ({
  isEditing = false,
  selectedRow,
  setSelectedRow,
  fetchVariables,
}) => {
  const auth = useAuth();
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ok, setOk] = useState<boolean>(false);
  const [variable, setVariable] = useState<Variable>({
    name: '',
    description: '',
    units: '',
    periodicity: '',
    responsible: '',
    source: '',
    method: '',
    developmentMethod: '',
    date: null,
    origin: '',
    accuracy: '',
    trust: '',
    isActive: 'SI',
    creationId: auth?.user?.id || DEFAULT_USER_ID,
  });

  useEffect(() => {
    if (isEditing && setSelectedRow) {
      setSelectedRow(undefined);
      if (selectedRow) {
        setVariable({
          id: selectedRow.id,
          name: selectedRow.name,
          description: selectedRow.description ?? '',
          units: selectedRow.units,
          periodicity: selectedRow.periodicity,
          responsible: selectedRow.responsible,
          source: selectedRow.source,
          method: selectedRow.method,
          developmentMethod: selectedRow.developmentMethod ?? '',
          date: selectedRow.date,
          origin: selectedRow.origin,
          accuracy: selectedRow.accuracy,
          trust: selectedRow.trust,
          isActive: selectedRow.isActive,
          creationId: auth?.user?.id || DEFAULT_USER_ID,
        });
      }
    }
  }, [auth?.user?.id, isEditing, selectedRow, setSelectedRow]);

  const handleOnClickSave = useCallback(async () => {
    setError('');
    const variableID = variable.id;
    setIsLoading(true);
    try {
      if (variableID) {
        await variableApi.updateVariable(variableID, variable);
      } else {
        await variableApi.createVariable(variable);
      }
      setOk(true);
    } catch {
      setError('Ha ocurrido un error al guardar la variable.');
    }
    setVariable({
      name: '',
      description: '',
      units: '',
      periodicity: '',
      responsible: '',
      source: '',
      method: '',
      developmentMethod: '',
      date: null,
      origin: '',
      accuracy: '',
      trust: '',
      isActive: 'SI',
      creationId: auth?.user?.id || DEFAULT_USER_ID,
    });
    if (isEditing && setSelectedRow) {
      setSelectedRow(undefined);
    }
    setIsLoading(false);
    if (fetchVariables) {
      await fetchVariables();
    }
  }, [auth?.user?.id, fetchVariables, isEditing, variable, setSelectedRow]);

  return (
    <>
      <Box
        component='form'
        sx={{
          '& .MuiTextField-root': { m: 1, width: '40ch' },
        }}
        noValidate
        autoComplete='off'
      >
        <TextField
          required
          label='Nombre'
          helperText='Nombre de la Variable'
          value={variable.name}
          onChange={(e) =>
            setVariable((prevState) => ({ ...prevState, name: e.target.value }))
          }
          error={!!!variable.name}
          inputProps={{ maxLength: 200 }}
        />
        <TextField
          label='Descripción'
          helperText='Descripción de la Variable'
          value={variable.description}
          onChange={(e) =>
            setVariable((prevState) => ({
              ...prevState,
              description: e.target.value,
            }))
          }
        />
        <TextField
          required
          label='Unidades'
          helperText='Unidades de la Variable'
          value={variable.units}
          onChange={(e) =>
            setVariable((prevState) => ({
              ...prevState,
              units: e.target.value,
            }))
          }
          error={!!!variable.units}
        />
        <TextField
          required
          label='Periodicidad'
          helperText='Periodicidad de la Variable'
          value={variable.periodicity}
          onChange={(e) =>
            setVariable((prevState) => ({
              ...prevState,
              periodicity: e.target.value,
            }))
          }
          error={!!!variable.periodicity}
          inputProps={{ maxLength: 30 }}
        />
        <TextField
          required
          label='Responsable'
          helperText='Responsable de la Variable'
          value={variable.responsible}
          onChange={(e) =>
            setVariable((prevState) => ({
              ...prevState,
              responsible: e.target.value,
            }))
          }
          error={!!!variable.responsible}
          inputProps={{ maxLength: 50 }}
        />
        <TextField
          required
          label='Fuente'
          helperText='Fuente de la Variable'
          value={variable.source}
          onChange={(e) =>
            setVariable((prevState) => ({
              ...prevState,
              source: e.target.value,
            }))
          }
          error={!!!variable.source}
          inputProps={{ maxLength: 100 }}
        />
        <TextField
          select
          required
          label='Método'
          helperText='Método de la Variable'
          value={variable.method}
          onChange={(e) =>
            setVariable((prevState) => ({
              ...prevState,
              method: e.target.value,
            }))
          }
          error={!!!variable.method}
        >
          {[
            { value: 'EXTERNO', label: 'EXTERNO' },
            { value: 'CALCULADO', label: 'CALCULADO' },
            { value: 'MEDIDO', label: 'MEDIDO' },
            { value: 'N/D', label: 'N/D' },
          ].map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label='Desarrollo Método'
          helperText='Desarrollo Método de la Variable'
          value={variable.developmentMethod}
          onChange={(e) =>
            setVariable((prevState) => ({
              ...prevState,
              developmentMethod: e.target.value,
            }))
          }
        />
        <DatePicker
          sx={{
            '& .Mui-error': { color: '#00000099 !important' },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#00000099 !important',
            },
          }}
          label='Fecha'
          slotProps={{
            textField: {
              helperText: 'DD/MM/AAAA',
            },
          }}
          value={dayjs(variable.date ?? '')}
          onChange={(newValue) =>
            setVariable((prevState) => ({
              ...prevState,
              date: newValue ? dayjs(newValue).format('YYYY-MM-DD') : null,
            }))
          }
        />
        <TextField
          required
          label='Origen'
          helperText='Origen de la Variable'
          value={variable.origin}
          onChange={(e) =>
            setVariable((prevState) => ({
              ...prevState,
              origin: e.target.value,
            }))
          }
          error={!!!variable.origin}
          inputProps={{ maxLength: 50 }}
        />
        <TextField
          select
          required
          label='Exactitud'
          helperText='Exactitud de la Variable'
          value={variable.accuracy}
          onChange={(e) =>
            setVariable((prevState) => ({
              ...prevState,
              accuracy: e.target.value,
            }))
          }
          error={!!!variable.accuracy}
        >
          {[
            { value: 'MALA', label: 'MALA' },
            { value: 'REGULAR', label: 'REGULAR' },
            { value: 'BUENA', label: 'BUENA' },
            { value: 'EXCELENTE', label: 'EXCELENTE' },
            { value: 'N/D', label: 'N/D' },
          ].map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          required
          label='Confianza'
          helperText='Confianza de la Variable'
          value={variable.trust}
          onChange={(e) =>
            setVariable((prevState) => ({
              ...prevState,
              trust: e.target.value,
            }))
          }
          error={!!!variable.trust}
        >
          {[
            { value: 'MALA', label: 'MALA' },
            { value: 'REGULAR', label: 'REGULAR' },
            { value: 'BUENA', label: 'BUENA' },
            { value: 'N/D', label: 'N/D' },
          ].map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        {isEditing && (
          <TextField
            select
            required
            label='Activo'
            value={variable.isActive}
            onChange={(e) =>
              setVariable((prevState) => ({
                ...prevState,
                isActive: e.target.value,
              }))
            }
          >
            {[
              { value: 'SI', label: 'SI' },
              { value: 'NO', label: 'NO' },
            ].map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Box>
      <Button
        variant='contained'
        sx={{ textTransform: 'none' }}
        onClick={handleOnClickSave}
        disabled={
          isLoading ||
          !!!variable.name ||
          !!!variable.units ||
          !!!variable.periodicity ||
          !!!variable.responsible ||
          !!!variable.source ||
          !!!variable.method ||
          !!!variable.origin ||
          !!!variable.accuracy ||
          !!!variable.trust ||
          !!!variable.isActive ||
          (isEditing && !!!variable.id)
        }
      >
        {isLoading ? <CircularProgress size={25} /> : 'Guardar'}
      </Button>
      {error && <Typography color='red'>{error}</Typography>}
      {ok && (
        <Typography marginTop={'10px'}>
          La variable se ha guardado correctamente.
        </Typography>
      )}
    </>
  );
};
