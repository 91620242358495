import { AxiosResponse } from 'axios';
import axios from './axiosConfig';

export interface Indicator {
  id?: number;
  parentId?: number | null;
  name: string;
  target?: string;
  formula: string;
  udFormula: string;
  periodicity: string;
  responsible: string;
  lowerLimit: number;
  upperLimit: number;
  targetValue: number;
  settingDate?: string | null;
  startValue: number;
  endValue: number;
  indicatorWeight?: number | null;
  priorityIndicator?: string;
  validityPeriod?: string;
  children?: string;
  comments?: string;
  isActive: string;
  creationId: number;
}

const createIndicator = async (
  indicator: Indicator
): Promise<AxiosResponse<Indicator>> => axios.post('/indicators', indicator);
const updateIndicator = async (
  id: number,
  indicator: Indicator
): Promise<AxiosResponse<Indicator>> =>
  axios.patch(`/indicators/${id}`, indicator);
const getIndicators = async (): Promise<AxiosResponse<Indicator[]>> =>
  axios.get('/indicators');

const indicatorApi = {
  createIndicator,
  updateIndicator,
  getIndicators,
};

export default indicatorApi;
