import { useState, useCallback } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import indicatorProcessMacroProcessApi, {
  IndicatorProcessMacroProcess,
} from '../../../../../api/indicatorProcessMacroProcess';
import { Form } from './Form';
import { Table } from '../shared/Table';

type Props = {
  action: string;
};

const columns: GridColDef[] = [
  { field: 'macroProcessId', headerName: 'ID Macro Proceso', width: 100 },
  { field: 'processId', headerName: 'ID Proceso', width: 100 },
  { field: 'indicatorId', headerName: 'ID Indicador Proceso', width: 100 },
  { field: 'macroProcessName', headerName: 'Nombre Macro Proceso', width: 300 },
  { field: 'processName', headerName: 'Nombre Proceso', width: 300 },
  {
    field: 'indicatorName',
    headerName: 'Nombre Indicador Proceso',
    width: 300,
  },
  {
    field: 'isActive',
    headerName: 'Activo',
    width: 90,
  },
];

export const Wrapper: React.FC<Props> = ({ action }) => {
  const [selectedRow, setSelectedRow] = useState<
    IndicatorProcessMacroProcess | undefined
  >();
  const [
    indicatorsProcessesMacroProcesses,
    setIndicatorsProcessesMacroProcesses,
  ] = useState<IndicatorProcessMacroProcess[] | []>([]);
  const [tableError, setTableError] = useState<string>('');
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true);

  const fetchIndicatorsProcessesMacroProcesses = useCallback(async () => {
    setIsTableLoading(true);
    try {
      const responseIndicatorsProcessesMacroProcesses =
        await indicatorProcessMacroProcessApi.getIndicatorsProcessesMacroProcesses();
      const indicatorsProcessesMacroProcessesData =
        responseIndicatorsProcessesMacroProcesses?.data;
      setIndicatorsProcessesMacroProcesses(
        indicatorsProcessesMacroProcessesData
      );
    } catch {
      setTableError(
        'Ha ocurrido un error al obtener los indicadores procesos macro procesos.'
      );
    }
    setIsTableLoading(false);
  }, []);

  return action === 'add' ? (
    <Form />
  ) : (
    <>
      <Table
        getRowId={(row) =>
          `${row.indicatorId}-${row.processId}-${row.macroProcessId}`
        }
        setSelectedRow={setSelectedRow}
        rows={indicatorsProcessesMacroProcesses}
        columns={columns}
        error={tableError}
        isLoading={isTableLoading}
        setIsTableLoading={setIsTableLoading}
        fetchTable={fetchIndicatorsProcessesMacroProcesses}
      />
      <Form
        isEditing={action === 'modify'}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        fetchIndicatorsProcessesMacroProcesses={
          fetchIndicatorsProcessesMacroProcesses
        }
      />
    </>
  );
};
