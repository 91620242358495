import { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
  Typography,
} from '@mui/material';
import typeParameterApi, {
  TypeParameter,
} from '../../../../../api/typeParameter';
import { useAuth } from '../../../../../Auth/Auth';

type Props = {
  isEditing?: boolean;
  selectedRow?: TypeParameter;
  setSelectedRow?: React.Dispatch<
    React.SetStateAction<TypeParameter | undefined>
  >;
  fetchTypeParameters?: () => Promise<void>;
};

const DEFAULT_USER_ID = 1;

export const Form: React.FC<Props> = ({
  isEditing = false,
  selectedRow,
  setSelectedRow,
  fetchTypeParameters,
}) => {
  const auth = useAuth();
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ok, setOk] = useState<boolean>(false);
  const [typeParameter, setTypeParameter] = useState<TypeParameter>({
    name: '',
    columnRCMI: '',
    comments: '',
    isActive: 'SI',
    creationId: auth?.user?.id || DEFAULT_USER_ID,
  });

  useEffect(() => {
    if (isEditing && setSelectedRow) {
      setSelectedRow(undefined);
      if (selectedRow) {
        setTypeParameter({
          id: selectedRow.id,
          name: selectedRow.name,
          columnRCMI: selectedRow.columnRCMI,
          comments: selectedRow.comments,
          isActive: selectedRow.isActive,
          creationId: auth?.user?.id || DEFAULT_USER_ID,
        });
      }
    }
  }, [auth?.user?.id, isEditing, selectedRow, setSelectedRow]);

  const handleOnClickSave = useCallback(async () => {
    setError('');
    const typeParameterId = typeParameter.id;
    setIsLoading(true);
    try {
      if (typeParameterId) {
        await typeParameterApi.updateTypeParameter(
          typeParameterId,
          typeParameter
        );
      } else {
        await typeParameterApi.createTypeParameter(typeParameter);
      }
      setOk(true);
    } catch {
      setError('Ha ocurrido un error al guardar el tipo de parámetro.');
    }
    setTypeParameter({
      name: '',
      columnRCMI: '',
      comments: '',
      isActive: 'SI',
      creationId: auth?.user?.id || DEFAULT_USER_ID,
    });
    if (isEditing && setSelectedRow) {
      setSelectedRow(undefined);
    }
    setIsLoading(false);
    if (fetchTypeParameters) {
      await fetchTypeParameters();
    }
  }, [
    auth?.user?.id,
    fetchTypeParameters,
    isEditing,
    typeParameter,
    setSelectedRow,
  ]);

  return (
    <>
      <Box
        component='form'
        sx={{
          '& .MuiTextField-root': { m: 1, width: '40ch' },
        }}
        noValidate
        autoComplete='off'
      >
        <TextField
          required
          label='Nombre'
          helperText='Nombre del tipo de Parámetro'
          value={typeParameter.name}
          onChange={(e) =>
            setTypeParameter((prevState) => ({
              ...prevState,
              name: e.target.value,
            }))
          }
          error={!!!typeParameter.name}
          inputProps={{ maxLength: 200 }}
        />
        <TextField
          required
          label='Columna RCMI'
          helperText='Columna RCMI'
          value={typeParameter.columnRCMI}
          onChange={(e) =>
            setTypeParameter((prevState) => ({
              ...prevState,
              columnRCMI: e.target.value,
            }))
          }
          error={!!!typeParameter.columnRCMI}
          inputProps={{ maxLength: 60 }}
        />
        <TextField
          label='Observaciones'
          helperText='Observaciones del tipo de Parámetro'
          value={typeParameter.comments ?? ''}
          onChange={(e) =>
            setTypeParameter((prevState) => ({
              ...prevState,
              comments: e.target.value,
            }))
          }
        />
        {isEditing && (
          <TextField
            select
            required
            label='Activo'
            value={typeParameter.isActive}
            onChange={(e) =>
              setTypeParameter((prevState) => ({
                ...prevState,
                isActive: e.target.value,
              }))
            }
          >
            {[
              { value: 'SI', label: 'SI' },
              { value: 'NO', label: 'NO' },
            ].map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Box>
      <Button
        variant='contained'
        sx={{ textTransform: 'none' }}
        onClick={handleOnClickSave}
        disabled={
          isLoading ||
          !!!typeParameter.name ||
          !!!typeParameter.columnRCMI ||
          !!!typeParameter.isActive ||
          (isEditing && !!!typeParameter.id)
        }
      >
        {isLoading ? <CircularProgress size={25} /> : 'Guardar'}
      </Button>
      {error && <Typography color='red'>{error}</Typography>}
      {ok && (
        <Typography marginTop={'10px'}>
          El tipo de Parámetro se ha guardado correctamente.
        </Typography>
      )}
    </>
  );
};
