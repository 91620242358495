import { useState } from 'react';
import {
  Typography,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { Table as TableEnum } from '../../../Menu';

type WrapperProps = {
  action: string;
};

type Props = {
  activeMenu: TableEnum;
  Wrapper: React.FC<WrapperProps>;
};

export const ViewWrapper: React.FC<Props> = ({ activeMenu, Wrapper }) => {
  const [action, setAction] = useState<string>('add');

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newAction: string
  ) => {
    setAction(newAction);
  };

  return (
    <Stack alignItems='center' paddingX='20px'>
      <Typography marginY='10px'>{activeMenu}</Typography>
      <ToggleButtonGroup
        color='primary'
        value={action}
        exclusive
        onChange={handleChange}
        aria-label='Action'
        sx={{ marginY: '10px' }}
      >
        <ToggleButton value='add' sx={{ width: '125px' }}>
          Alta
        </ToggleButton>
        <ToggleButton value='modify' sx={{ width: '125px' }}>
          Modificación
        </ToggleButton>
      </ToggleButtonGroup>
      <Wrapper action={action} />
    </Stack>
  );
};
