import { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
  Typography,
} from '@mui/material';
import indicatorProcessMacroProcessApi, {
  IndicatorProcessMacroProcess,
} from '../../../../../api/indicatorProcessMacroProcess';
import indicatorApi, { Indicator } from '../../../../../api/indicator';
import processApi, { Process } from '../../../../../api/process';
import macroProcessApi, { MacroProcess } from '../../../../../api/macroProcess';
import { useAuth } from '../../../../../Auth/Auth';
import { filterOnlyActive } from '../utils';
import { sortIndicatorByName } from '../IndicadoresPr/utils';
import { sortMacroProcessByName, sortProcessByName } from '../Procesos/utils';
import { filterByMacroProcessId } from './utils';

type Props = {
  isEditing?: boolean;
  selectedRow?: IndicatorProcessMacroProcess;
  setSelectedRow?: React.Dispatch<
    React.SetStateAction<IndicatorProcessMacroProcess | undefined>
  >;
  fetchIndicatorsProcessesMacroProcesses?: () => Promise<void>;
};

const DEFAULT_INDICATOR_ID = 0;
const DEFAULT_PROCESS_ID = 0;
const DEFAULT_MACRO_PROCESS_ID = 0;
const DEFAULT_USER_ID = 1;

export const Form: React.FC<Props> = ({
  isEditing = false,
  selectedRow,
  setSelectedRow,
  fetchIndicatorsProcessesMacroProcesses,
}) => {
  const auth = useAuth();
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ok, setOk] = useState<boolean>(false);
  const [indicatorProcessMacroProcess, setIndicatorProcessMacroProcess] =
    useState<IndicatorProcessMacroProcess>({
      indicatorId: DEFAULT_INDICATOR_ID,
      processId: DEFAULT_PROCESS_ID,
      macroProcessId: DEFAULT_MACRO_PROCESS_ID,
      indicatorName: '',
      processName: '',
      macroProcessName: '',
      isActive: 'SI',
      creationId: auth?.user?.id || DEFAULT_USER_ID,
    });
  const [indicators, setIndicators] = useState<Indicator[] | []>([]);
  const [indicatorsError, setIndicatorsError] = useState<string>('');
  const [isIndicatorsLoading, setIsIndicatorsLoading] = useState<boolean>(true);
  const [processes, setProcesses] = useState<Process[] | []>([]);
  const [filteredProcesses, setFilteredProcesses] = useState<Process[] | []>(
    []
  );
  const [processesError, setProcessesError] = useState<string>('');
  const [isProcessesLoading, setIsProcessesLoading] = useState<boolean>(true);
  const [macroProcesses, setMacroProcesses] = useState<MacroProcess[] | []>([]);
  const [macroProcessesError, setMacroProcessesError] = useState<string>('');
  const [isMacroProcessesLoading, setIsMacroProcessesLoading] =
    useState<boolean>(true);

  const fetchIndicators = useCallback(async () => {
    setIsIndicatorsLoading(true);
    try {
      const responseIndicators = await indicatorApi.getIndicators();
      const indicatorsData = responseIndicators?.data ?? [];
      setIndicators(filterOnlyActive(sortIndicatorByName(indicatorsData)));
    } catch {
      setIndicatorsError('Ha ocurrido un error al obtener los indicadores.');
    }
    setIsIndicatorsLoading(false);
  }, []);

  const fetchProcesses = useCallback(async () => {
    setIsProcessesLoading(true);
    try {
      const responseProcesses = await processApi.getProcesses();
      const processesData = responseProcesses?.data ?? [];
      const processes = sortProcessByName(filterOnlyActive(processesData));
      setProcesses(processes);
      setFilteredProcesses(processes);
    } catch {
      setProcessesError('Ha ocurrido un error al obtener las procesos.');
    }
    setIsProcessesLoading(false);
  }, []);

  const fetchMacroProcesses = useCallback(async () => {
    setIsMacroProcessesLoading(true);
    try {
      const responseMacroProcesses = await macroProcessApi.getMacroProcesses();
      const macroProcessesData = responseMacroProcesses?.data ?? [];
      setMacroProcesses(
        filterOnlyActive(sortMacroProcessByName(macroProcessesData))
      );
    } catch {
      setMacroProcessesError(
        'Ha ocurrido un error al obtener las macro procesos.'
      );
    }
    setIsMacroProcessesLoading(false);
  }, []);

  useEffect(() => {
    fetchIndicators();
    fetchProcesses();
    fetchMacroProcesses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilteredProcesses(
      filterByMacroProcessId(
        processes,
        indicatorProcessMacroProcess.macroProcessId
      )
    );
  }, [indicatorProcessMacroProcess.macroProcessId, processes]);

  useEffect(() => {
    if (isEditing && setSelectedRow) {
      if (selectedRow) {
        setIndicatorProcessMacroProcess({
          indicatorId: selectedRow.indicatorId,
          processId: selectedRow.processId,
          macroProcessId: selectedRow.macroProcessId,
          indicatorName: selectedRow.indicatorName,
          processName: selectedRow.processName,
          macroProcessName: selectedRow.macroProcessName,
          isActive: selectedRow.isActive,
          creationId: auth?.user?.id || DEFAULT_USER_ID,
        });
      }
    }
  }, [auth?.user?.id, isEditing, selectedRow, setSelectedRow]);

  const handleOnClickSave = useCallback(async () => {
    setError('');
    setIndicatorsError('');
    setProcessesError('');
    setMacroProcessesError('');
    const indicatorId = indicatorProcessMacroProcess.indicatorId;
    const processId = indicatorProcessMacroProcess.processId;
    const macroProcessId = indicatorProcessMacroProcess.macroProcessId;
    setIsLoading(true);
    try {
      if (indicatorId && processId && macroProcessId && isEditing) {
        await indicatorProcessMacroProcessApi.updateIndicatorProcessMacroProcess(
          selectedRow!.indicatorId,
          selectedRow!.processId,
          selectedRow!.macroProcessId,
          indicatorProcessMacroProcess
        );
      } else {
        await indicatorProcessMacroProcessApi.createIndicatorProcessMacroProcess(
          indicatorProcessMacroProcess
        );
      }
      setOk(true);
    } catch {
      setError(
        'Ha ocurrido un error al guardar el indicador proceso macro proceso.'
      );
    }
    setIndicatorProcessMacroProcess({
      indicatorId: DEFAULT_INDICATOR_ID,
      processId: DEFAULT_PROCESS_ID,
      macroProcessId: DEFAULT_MACRO_PROCESS_ID,
      indicatorName: '',
      processName: '',
      macroProcessName: '',
      isActive: 'SI',
      creationId: auth?.user?.id || DEFAULT_USER_ID,
    });
    if (isEditing && setSelectedRow) {
      setSelectedRow(undefined);
    }
    setIsLoading(false);
    if (fetchIndicatorsProcessesMacroProcesses) {
      await fetchIndicatorsProcessesMacroProcesses();
    }
  }, [
    indicatorProcessMacroProcess,
    auth?.user?.id,
    isEditing,
    setSelectedRow,
    fetchIndicatorsProcessesMacroProcesses,
    selectedRow,
  ]);

  const isIndicatorsIdInError =
    isEditing &&
    !indicators.some(
      (indicator) => indicator?.id === indicatorProcessMacroProcess.indicatorId
    );

  const isProcessesIdInError =
    isEditing &&
    !processes.some(
      (process) => process?.id === indicatorProcessMacroProcess.processId
    );

  const isMacroProcessesIdInError =
    isEditing &&
    !macroProcesses.some(
      (macroProcess) =>
        macroProcess?.id === indicatorProcessMacroProcess.macroProcessId
    );

  return (
    <>
      <Box
        component='form'
        sx={{
          '& .MuiTextField-root': { m: 1, width: '40ch' },
        }}
        noValidate
        autoComplete='off'
      >
        <TextField
          select
          required
          label='Nombre Macro Proceso'
          helperText='Nombre Macro Proceso'
          value={
            !macroProcesses.length ||
            !indicatorProcessMacroProcess.macroProcessId ||
            isMacroProcessesIdInError
              ? ''
              : indicatorProcessMacroProcess.macroProcessId
          }
          onChange={(e) =>
            setIndicatorProcessMacroProcess((prevState) => ({
              ...prevState,
              processId: DEFAULT_PROCESS_ID,
              processName: '',
              macroProcessId: e.target.value as unknown as number,
              macroProcessName:
                macroProcesses.find(
                  (macroProcess) =>
                    macroProcess.id === (e.target.value as unknown as number)
                )?.name ?? '',
            }))
          }
          error={
            !!!indicatorProcessMacroProcess.macroProcessId ||
            isMacroProcessesIdInError
          }
        >
          {macroProcesses.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          required
          label='Nombre Proceso'
          helperText='Nombre Proceso'
          value={
            !filteredProcesses.length ||
            !indicatorProcessMacroProcess.processId ||
            isProcessesIdInError
              ? ''
              : indicatorProcessMacroProcess.processId
          }
          onChange={(e) =>
            setIndicatorProcessMacroProcess((prevState) => ({
              ...prevState,
              processId: e.target.value as unknown as number,
              processName:
                filteredProcesses.find(
                  (process) =>
                    process.id === (e.target.value as unknown as number)
                )?.name ?? '',
            }))
          }
          error={
            !!!indicatorProcessMacroProcess.processId || isProcessesIdInError
          }
        >
          {filteredProcesses.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          required
          label='Nombre Indicador Proceso'
          helperText='Nombre Indicador Proceso'
          value={
            !indicators.length ||
            !indicatorProcessMacroProcess.indicatorId ||
            isIndicatorsIdInError
              ? ''
              : indicatorProcessMacroProcess.indicatorId
          }
          onChange={(e) =>
            setIndicatorProcessMacroProcess((prevState) => ({
              ...prevState,
              indicatorId: e.target.value as unknown as number,
              indicatorName:
                indicators.find(
                  (indicator) =>
                    indicator.id === (e.target.value as unknown as number)
                )?.name ?? '',
            }))
          }
          error={
            !!!indicatorProcessMacroProcess.indicatorId || isIndicatorsIdInError
          }
        >
          {indicators.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        {isEditing && (
          <TextField
            select
            required
            label='Activo'
            value={indicatorProcessMacroProcess.isActive}
            onChange={(e) =>
              setIndicatorProcessMacroProcess((prevState) => ({
                ...prevState,
                isActive: e.target.value,
              }))
            }
          >
            {[
              { value: 'SI', label: 'SI' },
              { value: 'NO', label: 'NO' },
            ].map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Box>
      <Button
        variant='contained'
        sx={{ textTransform: 'none' }}
        onClick={handleOnClickSave}
        disabled={
          isLoading ||
          isIndicatorsLoading ||
          isProcessesLoading ||
          isMacroProcessesLoading ||
          !!!indicatorProcessMacroProcess.indicatorId ||
          !!!indicatorProcessMacroProcess.processId ||
          !!!indicatorProcessMacroProcess.macroProcessId ||
          !!!indicatorProcessMacroProcess.indicatorName ||
          !!!indicatorProcessMacroProcess.processName ||
          !!!indicatorProcessMacroProcess.macroProcessName ||
          !!!indicatorProcessMacroProcess.isActive ||
          isIndicatorsIdInError ||
          isProcessesIdInError ||
          isMacroProcessesIdInError ||
          (isEditing && !selectedRow)
        }
      >
        {isLoading ||
        isIndicatorsLoading ||
        isProcessesLoading ||
        isMacroProcessesLoading ? (
          <CircularProgress size={25} />
        ) : (
          'Guardar'
        )}
      </Button>
      {(error || indicatorsError || processesError || macroProcessesError) && (
        <Typography color='red'>
          {error || indicatorsError || processesError || macroProcessesError}
        </Typography>
      )}
      {ok && (
        <Typography marginTop={'10px'}>
          El indicador proceso macro proceso se ha guardado correctamente.
        </Typography>
      )}
    </>
  );
};
