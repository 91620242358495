import { useEffect } from 'react';
import {
  GridRowsProp,
  GridColDef,
  GridSortModel,
  GridRowIdGetter,
} from '@mui/x-data-grid';
import { CircularProgress, Typography } from '@mui/material';
import { MuiTable } from '../shared/MuiTable';

type Props = {
  getRowId?: GridRowIdGetter;
  setSelectedRow: React.Dispatch<React.SetStateAction<any | undefined>>;
  rows: GridRowsProp;
  columns: GridColDef[];
  error: string;
  isLoading: boolean;
  setIsTableLoading: React.Dispatch<React.SetStateAction<boolean>>;
  fetchTable: () => Promise<void>;
  sortedBy?: GridSortModel;
};

export const Table: React.FC<Props> = ({
  getRowId,
  setSelectedRow,
  rows,
  columns,
  error,
  isLoading,
  setIsTableLoading,
  fetchTable,
  sortedBy = [],
}) => {
  useEffect(() => {
    fetchTable();

    return () => {
      setIsTableLoading(true);
    };
  }, [fetchTable, setIsTableLoading]);

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <MuiTable
          getRowId={getRowId}
          setSelectedRow={setSelectedRow}
          rows={rows}
          columns={columns}
          sortedBy={sortedBy}
        />
      )}
      {error && <Typography color='red'>{error}</Typography>}
    </>
  );
};
