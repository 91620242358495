import { Indicator } from '../../../../../api/indicator';

export const sortIndicatorByName = (indicators: Indicator[]): Indicator[] =>
  indicators.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  });

export const filterOnlyParent = (array: Indicator[]) =>
  array.filter((object) => !object?.parentId);
