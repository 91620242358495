import { TypeMacroProcess } from '../../../../../api/typeMacroProcess';

export const sortTypeMacroProcessByName = (
  typeMacroProcesses: TypeMacroProcess[]
): TypeMacroProcess[] =>
  typeMacroProcesses.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  });
