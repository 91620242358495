import { useState, useCallback } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import indicatorActionApi, {
  IndicatorAction,
} from '../../../../../api/indicatorAction';
import { Form } from './Form';
import { Table } from '../shared/Table';

type Props = {
  action: string;
};

const columns: GridColDef[] = [
  { field: 'indicatorId', headerName: 'ID Indicador Proceso', width: 100 },
  { field: 'actionId', headerName: 'ID Acción', width: 100 },
  {
    field: 'indicatorName',
    headerName: 'Nombre Indicador Proceso',
    width: 300,
  },
  { field: 'actionName', headerName: 'Nombre Acción', width: 300 },
  {
    field: 'isActive',
    headerName: 'Activo',
    width: 90,
  },
];

export const Wrapper: React.FC<Props> = ({ action }) => {
  const [selectedRow, setSelectedRow] = useState<IndicatorAction | undefined>();
  const [indicatorsActions, setIndicatorsActions] = useState<
    IndicatorAction[] | []
  >([]);
  const [tableError, setTableError] = useState<string>('');
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true);

  const fetchIndicatorsActions = useCallback(async () => {
    setIsTableLoading(true);
    try {
      const responseIndicatorsActions =
        await indicatorActionApi.getIndicatorsActions();
      const indicatorsActionsData = responseIndicatorsActions?.data;
      setIndicatorsActions(indicatorsActionsData);
    } catch {
      setTableError(
        'Ha ocurrido un error al obtener los indicadores acciones.'
      );
    }
    setIsTableLoading(false);
  }, []);

  return action === 'add' ? (
    <Form />
  ) : (
    <>
      <Table
        getRowId={(row) => `${row.indicatorId}-${row.actionId}`}
        setSelectedRow={setSelectedRow}
        rows={indicatorsActions}
        columns={columns}
        error={tableError}
        isLoading={isTableLoading}
        setIsTableLoading={setIsTableLoading}
        fetchTable={fetchIndicatorsActions}
      />
      <Form
        isEditing={action === 'modify'}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        fetchIndicatorsActions={fetchIndicatorsActions}
      />
    </>
  );
};
