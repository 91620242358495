import { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import indicatorApi, { Indicator } from '../../../../../api/indicator';
import { useAuth } from '../../../../../Auth/Auth';
import { sortIndicatorByName, filterOnlyParent } from './utils';
import { filterOnlyActive } from '../utils';

type Props = {
  isEditing?: boolean;
  selectedRow?: Indicator;
  setSelectedRow?: React.Dispatch<React.SetStateAction<Indicator | undefined>>;
  fetchIndicators?: () => Promise<void>;
};

const DEFAULT_USER_ID = 1;

export const Form: React.FC<Props> = ({
  isEditing = false,
  selectedRow,
  setSelectedRow,
  fetchIndicators,
}) => {
  const auth = useAuth();
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ok, setOk] = useState<boolean>(false);
  const [indicator, setIndicator] = useState<Indicator>({
    parentId: null,
    name: '',
    target: '',
    formula: '',
    udFormula: '',
    periodicity: '',
    responsible: '',
    lowerLimit: 0,
    upperLimit: 0,
    targetValue: 0,
    settingDate: null,
    startValue: 0,
    endValue: 0,
    indicatorWeight: null,
    priorityIndicator: 'NO',
    validityPeriod: '',
    children: '',
    comments: '',
    isActive: 'SI',
    creationId: auth?.user?.id || DEFAULT_USER_ID,
  });
  const [indicators, setIndicators] = useState<Indicator[] | []>([]);
  const [indicatorsError, setIndicatorsError] = useState<string>('');
  const [isIndicatorsLoading, setIsIndicatorsLoading] = useState<boolean>(true);

  const fetchIndicatorsLocal = useCallback(async () => {
    setIsIndicatorsLoading(true);
    try {
      const responseIndicators = await indicatorApi.getIndicators();
      const indicatorsData = responseIndicators?.data ?? [];
      setIndicators(
        filterOnlyActive(filterOnlyParent(sortIndicatorByName(indicatorsData)))
      );
    } catch {
      setIndicatorsError('Ha ocurrido un error al obtener los indicadores.');
    }
    setIsIndicatorsLoading(false);
  }, []);

  useEffect(() => {
    fetchIndicatorsLocal();
    if (isEditing && setSelectedRow) {
      setSelectedRow(undefined);
      if (selectedRow) {
        setIndicator({
          id: selectedRow.id,
          parentId: selectedRow.parentId,
          name: selectedRow.name,
          target: selectedRow.target ?? '',
          formula: selectedRow.formula,
          udFormula: selectedRow.udFormula,
          periodicity: selectedRow.periodicity,
          responsible: selectedRow.responsible,
          lowerLimit: selectedRow.lowerLimit,
          upperLimit: selectedRow.upperLimit,
          targetValue: selectedRow.targetValue,
          settingDate: selectedRow.settingDate,
          startValue: selectedRow.startValue,
          endValue: selectedRow.endValue,
          indicatorWeight: selectedRow.indicatorWeight,
          priorityIndicator: selectedRow.priorityIndicator ?? 'NO',
          validityPeriod: selectedRow.validityPeriod ?? '',
          children: selectedRow.children ?? '',
          comments: selectedRow.comments ?? '',
          isActive: selectedRow.isActive,
          creationId: auth?.user?.id || DEFAULT_USER_ID,
        });
      }
    }
  }, [
    auth?.user?.id,
    fetchIndicatorsLocal,
    isEditing,
    selectedRow,
    setSelectedRow,
  ]);

  const handleOnClickSave = useCallback(async () => {
    setError('');
    setIndicatorsError('');
    const indicatorId = indicator.id;
    setIsLoading(true);
    try {
      if (indicatorId) {
        await indicatorApi.updateIndicator(indicatorId, indicator);
      } else {
        await indicatorApi.createIndicator(indicator);
      }
      setOk(true);
    } catch {
      setError('Ha ocurrido un error al guardar el indicador.');
    }
    setIndicator({
      parentId: null,
      name: '',
      target: '',
      formula: '',
      udFormula: '',
      periodicity: '',
      responsible: '',
      lowerLimit: 0,
      upperLimit: 0,
      targetValue: 0,
      settingDate: null,
      startValue: 0,
      endValue: 0,
      indicatorWeight: null,
      priorityIndicator: 'NO',
      validityPeriod: '',
      children: '',
      comments: '',
      isActive: 'SI',
      creationId: auth?.user?.id || DEFAULT_USER_ID,
    });
    if (isEditing && setSelectedRow) {
      setSelectedRow(undefined);
    }
    setIsLoading(false);
    if (fetchIndicators) {
      await fetchIndicators();
    }
  }, [indicator, auth?.user?.id, isEditing, setSelectedRow, fetchIndicators]);

  return (
    <>
      <Box
        component='form'
        sx={{
          '& .MuiTextField-root': { m: 1, width: '40ch' },
        }}
        noValidate
        autoComplete='off'
      >
        <TextField
          select
          label='ID Padre'
          helperText='ID Padre'
          value={
            !indicators.length || !indicator.parentId ? '' : indicator.parentId
          }
          onChange={(e) =>
            setIndicator((prevState) => ({
              ...prevState,
              parentId: e.target.value as unknown as number,
            }))
          }
        >
          {indicators.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          type=''
          required
          label='Nombre'
          helperText='Nombre'
          value={indicator.name ?? ''}
          onChange={(e) =>
            setIndicator((prevState) => ({
              ...prevState,
              name: e.target.value,
            }))
          }
          error={!!!indicator.name}
          inputProps={{ maxLength: 200 }}
        />
        <TextField
          type=''
          label='Objetivo'
          helperText='Objetivo'
          value={indicator.target ?? ''}
          onChange={(e) =>
            setIndicator((prevState) => ({
              ...prevState,
              target: e.target.value,
            }))
          }
        />
        <TextField
          type=''
          required
          label='Fórmula'
          helperText='Fórmula'
          value={indicator.formula ?? ''}
          onChange={(e) =>
            setIndicator((prevState) => ({
              ...prevState,
              formula: e.target.value,
            }))
          }
          error={!!!indicator.formula}
        />
        <TextField
          type=''
          required
          label='UD Fórmula'
          helperText='UD Fórmula'
          value={indicator.udFormula ?? ''}
          onChange={(e) =>
            setIndicator((prevState) => ({
              ...prevState,
              udFormula: e.target.value,
            }))
          }
          error={!!!indicator.udFormula}
          inputProps={{ maxLength: 200 }}
        />
        <TextField
          type=''
          required
          label='Periodicidad'
          helperText='Periodicidad'
          value={indicator.periodicity ?? ''}
          onChange={(e) =>
            setIndicator((prevState) => ({
              ...prevState,
              periodicity: e.target.value,
            }))
          }
          error={!!!indicator.periodicity}
          inputProps={{ maxLength: 200 }}
        />
        <TextField
          type=''
          required
          label='Responsable'
          helperText='Responsable'
          value={indicator.responsible ?? ''}
          onChange={(e) =>
            setIndicator((prevState) => ({
              ...prevState,
              responsible: e.target.value,
            }))
          }
          error={!!!indicator.responsible}
        />
        <TextField
          type='number'
          required
          label='Límite Inferior'
          helperText='Límite Inferior'
          value={indicator.lowerLimit ?? ''}
          onChange={(e) =>
            setIndicator((prevState) => ({
              ...prevState,
              lowerLimit: e.target.value as unknown as number,
            }))
          }
          error={indicator.lowerLimit !== 0 && !!!indicator.lowerLimit}
          inputProps={{ maxLength: 17 }}
        />
        <TextField
          type='number'
          required
          label='Límite Superior'
          helperText='Límite Superior'
          value={indicator.upperLimit ?? ''}
          onChange={(e) =>
            setIndicator((prevState) => ({
              ...prevState,
              upperLimit: e.target.value as unknown as number,
            }))
          }
          error={indicator.upperLimit !== 0 && !!!indicator.upperLimit}
          inputProps={{ maxLength: 17 }}
        />
        <TextField
          type='number'
          required
          label='Valor Objetivo'
          helperText='Valor Objetivo'
          value={indicator.targetValue ?? ''}
          onChange={(e) =>
            setIndicator((prevState) => ({
              ...prevState,
              targetValue: e.target.value as unknown as number,
            }))
          }
          error={indicator.targetValue !== 0 && !!!indicator.targetValue}
          inputProps={{ maxLength: 17 }}
        />
        <DatePicker
          sx={{
            '& .Mui-error': { color: '#00000099 !important' },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#00000099 !important',
            },
          }}
          label='Fecha de Parametrización'
          slotProps={{
            textField: {
              helperText: 'DD/MM/AAAA',
            },
          }}
          value={dayjs(indicator.settingDate ?? '')}
          onChange={(newValue) =>
            setIndicator((prevState) => ({
              ...prevState,
              settingDate: newValue
                ? dayjs(newValue).format('YYYY-MM-DD')
                : null,
            }))
          }
        />
        <TextField
          type='number'
          required
          label='Star Value'
          helperText='Star Value'
          value={indicator.startValue ?? ''}
          onChange={(e) =>
            setIndicator((prevState) => ({
              ...prevState,
              startValue: e.target.value as unknown as number,
            }))
          }
          error={indicator.startValue !== 0 && !!!indicator.startValue}
          inputProps={{ maxLength: 17 }}
        />
        <TextField
          type='number'
          required
          label='End Value'
          helperText='End Value'
          value={indicator.endValue ?? ''}
          onChange={(e) =>
            setIndicator((prevState) => ({
              ...prevState,
              endValue: e.target.value as unknown as number,
            }))
          }
          error={indicator.endValue !== 0 && !!!indicator.endValue}
          inputProps={{ maxLength: 17 }}
        />
        <TextField
          type='number'
          label='Peso Indicador'
          helperText='Peso Indicador'
          value={indicator.indicatorWeight ?? ''}
          onChange={(e) =>
            setIndicator((prevState) => ({
              ...prevState,
              indicatorWeight: e.target.value as unknown as number,
            }))
          }
          inputProps={{ maxLength: 17 }}
        />
        <TextField
          select
          label='Indicador Prioritario'
          value={indicator.priorityIndicator}
          onChange={(e) =>
            setIndicator((prevState) => ({
              ...prevState,
              priorityIndicator: e.target.value,
            }))
          }
        >
          {[
            { value: 'SI', label: 'SI' },
            { value: 'NO', label: 'NO' },
          ].map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          type=''
          label='Período de Validez'
          helperText='Período de Validez'
          value={indicator.validityPeriod ?? ''}
          onChange={(e) =>
            setIndicator((prevState) => ({
              ...prevState,
              validityPeriod: e.target.value,
            }))
          }
          inputProps={{ maxLength: 200 }}
        />
        <TextField
          type=''
          label='Hijos'
          helperText='Hijos'
          value={indicator.children ?? ''}
          onChange={(e) =>
            setIndicator((prevState) => ({
              ...prevState,
              children: e.target.value,
            }))
          }
          inputProps={{ maxLength: 60 }}
        />
        <TextField
          type=''
          label='Observaciones'
          helperText='Observaciones'
          value={indicator.comments ?? ''}
          onChange={(e) =>
            setIndicator((prevState) => ({
              ...prevState,
              comments: e.target.value,
            }))
          }
        />
        {isEditing && (
          <TextField
            select
            required
            label='Activo'
            value={indicator.isActive}
            onChange={(e) =>
              setIndicator((prevState) => ({
                ...prevState,
                isActive: e.target.value,
              }))
            }
          >
            {[
              { value: 'SI', label: 'SI' },
              { value: 'NO', label: 'NO' },
            ].map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Box>
      <Button
        variant='contained'
        sx={{ textTransform: 'none' }}
        onClick={handleOnClickSave}
        disabled={
          isLoading ||
          isIndicatorsLoading ||
          !!!indicator.name ||
          !!!indicator.formula ||
          !!!indicator.udFormula ||
          !!!indicator.periodicity ||
          !!!indicator.responsible ||
          (indicator.lowerLimit !== 0 && !!!indicator.lowerLimit) ||
          (indicator.upperLimit !== 0 && !!!indicator.upperLimit) ||
          (indicator.targetValue !== 0 && !!!indicator.targetValue) ||
          (indicator.startValue !== 0 && !!!indicator.startValue) ||
          (indicator.endValue !== 0 && !!!indicator.endValue) ||
          !!!indicator.isActive ||
          (isEditing && !!!indicator.id)
        }
      >
        {isLoading || isIndicatorsLoading ? (
          <CircularProgress size={25} />
        ) : (
          'Guardar'
        )}
      </Button>
      {(error || indicatorsError) && (
        <Typography color='red'>{error || indicatorsError}</Typography>
      )}
      {ok && (
        <Typography marginTop={'10px'}>
          El indicador se ha guardado correctamente.
        </Typography>
      )}
    </>
  );
};
