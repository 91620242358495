import { AxiosResponse } from 'axios';
import axios from './axiosConfig';

export interface IndicatorVariable {
  indicatorId: number;
  variableId: number;
  indicatorName: string;
  variableName: string;
  isActive: string;
  creationId: number;
}

const createIndicatorVariable = async (
  indicatorVariable: IndicatorVariable
): Promise<AxiosResponse<IndicatorVariable>> =>
  axios.post('/indicatorsVariables', indicatorVariable);
const updateIndicatorVariable = async (
  indicatorId: number,
  variableId: number,
  indicatorVariable: IndicatorVariable
): Promise<AxiosResponse<IndicatorVariable>> =>
  axios.patch(
    `/indicatorsVariables/${indicatorId}/${variableId}`,
    indicatorVariable
  );
const getIndicatorsVariables = async (): Promise<
  AxiosResponse<IndicatorVariable[]>
> => axios.get('/indicatorsVariables');

const indicatorVariableApi = {
  createIndicatorVariable,
  updateIndicatorVariable,
  getIndicatorsVariables,
};

export default indicatorVariableApi;
