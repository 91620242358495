import { AxiosResponse } from 'axios';
import axios from './axiosConfig';

export interface Action {
  id?: number;
  name: string;
  comments?: string;
  isActive: string;
  creationId: number;
}

const createAction = async (action: Action): Promise<AxiosResponse<Action>> =>
  axios.post('/actions', action);
const updateAction = async (
  id: number,
  action: Action
): Promise<AxiosResponse<Action>> => axios.patch(`/actions/${id}`, action);
const getActions = async (): Promise<AxiosResponse<Action[]>> =>
  axios.get('/actions');

const actionApi = { createAction, updateAction, getActions };

export default actionApi;
