import { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  OutlinedInput,
  CircularProgress,
  Typography,
} from '@mui/material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import userApi, { User } from '../../../../../api/user';
import roleApi, { Role } from '../../../../../api/role';
import { useAuth } from '../../../../../Auth/Auth';
import { generatePassword, sortRolesByName, isValidEmail } from './utils';
import { filterOnlyActive } from '../utils';

type Props = {
  isEditing?: boolean;
  selectedRow?: User;
  setSelectedRow?: React.Dispatch<React.SetStateAction<User | undefined>>;
  fetchUsers?: () => Promise<void>;
};

const DEFAULT_ROLE_ID = 0;
const DEFAULT_USER_ID = 1;

export const Form: React.FC<Props> = ({
  isEditing = false,
  selectedRow,
  setSelectedRow,
  fetchUsers,
}) => {
  const auth = useAuth();
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ok, setOk] = useState<boolean>(false);
  const [user, setUser] = useState<User>({
    name: '',
    firstLastname: '',
    secondLastname: '',
    email: '',
    address: '',
    telephone: '',
    roleId: DEFAULT_ROLE_ID,
    password: '',
    isActive: 'SI',
    creationId: auth?.user?.id || DEFAULT_USER_ID,
  });
  const [roles, setRoles] = useState<Role[] | []>([]);
  const [rolesError, setRolesError] = useState<string>('');
  const [isRolesLoading, setIsRolesLoading] = useState<boolean>(true);
  const [passwordWasChanged, setPasswordWasChanged] = useState<boolean>(false);

  const fetchRoles = useCallback(async () => {
    setIsRolesLoading(true);
    try {
      const responseRoles = await roleApi.getRoles();
      const rolesData = responseRoles?.data ?? [];
      setRoles(filterOnlyActive(sortRolesByName(rolesData)));
    } catch {
      setRolesError('Ha ocurrido un error al obtener los roles.');
    }
    setIsRolesLoading(false);
  }, []);

  useEffect(() => {
    fetchRoles();
    if (isEditing && setSelectedRow) {
      setSelectedRow(undefined);
      if (selectedRow) {
        setUser({
          id: selectedRow.id,
          name: selectedRow.name,
          firstLastname: selectedRow.firstLastname,
          secondLastname: selectedRow.secondLastname,
          email: selectedRow.email,
          address: selectedRow.address,
          telephone: selectedRow.telephone,
          roleId: selectedRow.roleId,
          password: selectedRow.password,
          isActive: selectedRow.isActive,
          creationId: auth?.user?.id || DEFAULT_USER_ID,
        });
      }
    }
  }, [auth?.user?.id, fetchRoles, isEditing, selectedRow, setSelectedRow]);

  const handleOnClickSave = useCallback(async () => {
    setError('');
    setRolesError('');
    const userId = user.id;
    setIsLoading(true);
    try {
      if (userId) {
        await userApi.updateUser(userId, user, passwordWasChanged);
      } else {
        await userApi.createUser(user);
      }
      setOk(true);
    } catch {
      setError('Ha ocurrido un error al guardar el usuario.');
    }
    setUser({
      name: '',
      firstLastname: '',
      secondLastname: '',
      email: '',
      address: '',
      telephone: '',
      roleId: DEFAULT_ROLE_ID,
      password: '',
      isActive: 'SI',
      creationId: auth?.user?.id || DEFAULT_USER_ID,
    });
    if (isEditing && setSelectedRow) {
      setSelectedRow(undefined);
    }
    setIsLoading(false);
    if (fetchUsers) {
      await fetchUsers();
    }
  }, [
    user,
    auth?.user?.id,
    isEditing,
    setSelectedRow,
    fetchUsers,
    passwordWasChanged,
  ]);

  const isRoleIdInError =
    isEditing && !roles.some((rol) => rol?.id === user.roleId);

  return (
    <>
      <Box
        component='form'
        sx={{
          '& .MuiTextField-root': { m: 1, width: '40ch' },
        }}
        noValidate
        autoComplete='off'
      >
        <TextField
          required
          label='Nombre'
          helperText='Nombre'
          value={user.name}
          onChange={(e) =>
            setUser((prevState) => ({ ...prevState, name: e.target.value }))
          }
          error={!!!user.name}
          inputProps={{ maxLength: 100 }}
        />
        <TextField
          required
          label='Primer Apellido'
          helperText='Primer Apellido'
          value={user.firstLastname}
          onChange={(e) =>
            setUser((prevState) => ({
              ...prevState,
              firstLastname: e.target.value,
            }))
          }
          error={!!!user.firstLastname}
          inputProps={{ maxLength: 100 }}
        />
        <TextField
          label='Segundo Apellido'
          helperText='Segundo Apellido'
          value={user.secondLastname ?? ''}
          onChange={(e) =>
            setUser((prevState) => ({
              ...prevState,
              secondLastname: e.target.value,
            }))
          }
          inputProps={{ maxLength: 100 }}
        />
        <TextField
          required
          label='Email'
          helperText='Email'
          value={user.email}
          onChange={(e) =>
            setUser((prevState) => ({ ...prevState, email: e.target.value }))
          }
          error={!!!user.email || !isValidEmail(user.email)}
          inputProps={{ maxLength: 100 }}
        />
        <TextField
          label='Dirección'
          helperText='Dirección'
          value={user.address ?? ''}
          onChange={(e) =>
            setUser((prevState) => ({ ...prevState, address: e.target.value }))
          }
          inputProps={{ maxLength: 100 }}
        />
        <TextField
          label='Teléfono'
          helperText='Teléfono'
          value={user.telephone ?? ''}
          onChange={(e) =>
            setUser((prevState) => ({
              ...prevState,
              telephone: e.target.value,
            }))
          }
          inputProps={{ maxLength: 50 }}
        />
        <TextField
          select
          required
          label='Rol'
          helperText='Rol'
          value={
            !roles.length || !user.roleId || isRoleIdInError ? '' : user.roleId
          }
          onChange={(e) =>
            setUser((prevState) => ({
              ...prevState,
              roleId: e.target.value as unknown as number,
            }))
          }
          error={!!!user.roleId || isRoleIdInError}
        >
          {roles.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        <FormControl
          sx={{ m: 1, width: '25ch' }}
          variant='outlined'
          required
          error={!!!user.password}
        >
          <InputLabel htmlFor='outlined-adornment-password' required>
            Contraseña
          </InputLabel>
          <OutlinedInput
            type='text'
            disabled
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={() => {
                    setPasswordWasChanged(true);
                    setUser((prevState) => ({
                      ...prevState,
                      password: generatePassword(),
                    }));
                  }}
                  edge='end'
                >
                  <AutoFixHighIcon />
                </IconButton>
              </InputAdornment>
            }
            label='Password'
            value={
              isEditing && !passwordWasChanged ? '********' : user.password
            }
            error={!!!user.password}
          />
        </FormControl>
        {isEditing && (
          <TextField
            select
            required
            label='Activo'
            value={user.isActive}
            onChange={(e) =>
              setUser((prevState) => ({
                ...prevState,
                isActive: e.target.value,
              }))
            }
          >
            {[
              { value: 'SI', label: 'SI' },
              { value: 'NO', label: 'NO' },
            ].map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Box>
      <Button
        variant='contained'
        sx={{ textTransform: 'none' }}
        onClick={handleOnClickSave}
        disabled={
          isLoading ||
          isRolesLoading ||
          !!!user.name ||
          !!!user.firstLastname ||
          !!!user.email ||
          !isValidEmail(user.email) ||
          !!!user.roleId ||
          isRoleIdInError ||
          !!!user.password ||
          !!!user.isActive ||
          (isEditing && !!!user.id)
        }
      >
        {isLoading || isRolesLoading ? (
          <CircularProgress size={25} />
        ) : (
          'Guardar'
        )}
      </Button>
      {(error || rolesError) && (
        <Typography color='red'>{error || rolesError}</Typography>
      )}
      {ok && (
        <Typography marginTop={'10px'}>
          El usuario se ha guardado correctamente.
        </Typography>
      )}
    </>
  );
};
