import { TypeParameter } from '../../../../../api/typeParameter';

export const sortTypeParametersByName = (
  typeParameters: TypeParameter[]
): TypeParameter[] =>
  typeParameters.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  });
