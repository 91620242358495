import { AxiosResponse } from 'axios';
import axios from './axiosConfig';

export interface Role {
  id?: number;
  name: string;
  description?: string;
  isActive: string;
  creationId: number;
}

const createRole = async (role: Role): Promise<AxiosResponse<Role>> =>
  axios.post('/roles', role);
const updateRole = async (
  id: number,
  role: Role
): Promise<AxiosResponse<Role>> => axios.patch(`/roles/${id}`, role);
const getRoles = async (): Promise<AxiosResponse<Role[]>> =>
  axios.get('/roles');

const roleApi = { createRole, updateRole, getRoles };

export default roleApi;
