import { AxiosResponse } from 'axios';
import axios from './axiosConfig';

export interface MacroProcess {
  id?: number;
  typeMacroProcessId: number;
  name: string;
  description?: string;
  priority?: number | null;
  weight?: number | null;
  risks?: string;
  metaNumerics: number;
  mprResult: number;
  lowerLimit: number;
  upperLimit: number;
  startValue: number;
  endValue: number;
  settingDate?: string | null;
  validityPeriod?: string;
  comments?: string;
  isActive: string;
  creationId: number;
}

const createMacroProcess = async (
  macroProcess: MacroProcess
): Promise<AxiosResponse<MacroProcess>> =>
  axios.post('/macroProcesses', macroProcess);
const updateMacroProcess = async (
  id: number,
  macroProcess: MacroProcess
): Promise<AxiosResponse<MacroProcess>> =>
  axios.patch(`/macroProcesses/${id}`, macroProcess);
const getMacroProcesses = async (): Promise<AxiosResponse<MacroProcess[]>> =>
  axios.get('/macroProcesses');

const macroProcessApi = {
  createMacroProcess,
  updateMacroProcess,
  getMacroProcesses,
};

export default macroProcessApi;
