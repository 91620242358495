import { AxiosResponse } from 'axios';
import axios from './axiosConfig';

export interface IndicatorProcessMacroProcess {
  indicatorId: number;
  processId: number;
  macroProcessId: number;
  indicatorName: string;
  processName: string;
  macroProcessName: string;
  isActive: string;
  creationId: number;
}

const createIndicatorProcessMacroProcess = async (
  indicatorProcessMacroProcess: IndicatorProcessMacroProcess
): Promise<AxiosResponse<IndicatorProcessMacroProcess>> =>
  axios.post(
    '/indicatorsProcessesMacroProcesses',
    indicatorProcessMacroProcess
  );
const updateIndicatorProcessMacroProcess = async (
  indicatorId: number,
  processId: number,
  macroProcessId: number,
  indicatorProcessMacroProcess: IndicatorProcessMacroProcess
): Promise<AxiosResponse<IndicatorProcessMacroProcess>> =>
  axios.patch(
    `/indicatorsProcessesMacroProcesses/${indicatorId}/${processId}/${macroProcessId}`,
    indicatorProcessMacroProcess
  );
const getIndicatorsProcessesMacroProcesses = async (): Promise<
  AxiosResponse<IndicatorProcessMacroProcess[]>
> => axios.get('/indicatorsProcessesMacroProcesses');

const indicatorProcessMacroProcessApi = {
  createIndicatorProcessMacroProcess,
  updateIndicatorProcessMacroProcess,
  getIndicatorsProcessesMacroProcesses,
};

export default indicatorProcessMacroProcessApi;
