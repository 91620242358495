import { useState, useCallback } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import actionApi, { Action } from '../../../../../api/action';
import { Form } from './Form';
import { Table } from '../shared/Table';

type Props = {
  action: string;
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'name', headerName: 'Nombre', width: 400 },
  { field: 'comments', headerName: 'Observaciones', width: 300 },
  {
    field: 'isActive',
    headerName: 'Activo',
    type: 'number',
    width: 90,
  },
];

export const Wrapper: React.FC<Props> = ({ action }) => {
  const [selectedRow, setSelectedRow] = useState<Action | undefined>();
  const [actions, setActions] = useState<Action[] | []>([]);
  const [tableError, setTableError] = useState<string>('');
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true);

  const fetchActions = useCallback(async () => {
    setIsTableLoading(true);
    try {
      const responseActions = await actionApi.getActions();
      const actionsData = responseActions?.data;
      setActions(actionsData);
    } catch {
      setTableError('Ha ocurrido un error al obtener las acciones.');
    }
    setIsTableLoading(false);
  }, []);

  return action === 'add' ? (
    <Form />
  ) : (
    <>
      <Table
        setSelectedRow={setSelectedRow}
        rows={actions}
        columns={columns}
        error={tableError}
        isLoading={isTableLoading}
        setIsTableLoading={setIsTableLoading}
        fetchTable={fetchActions}
      />
      <Form
        isEditing={action === 'modify'}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        fetchActions={fetchActions}
      />
    </>
  );
};
