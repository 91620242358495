import { useState } from 'react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Stack,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useAuth } from '../Auth/Auth';

const MenuAppBar: React.FC = () => {
  const isMobile = useMediaQuery('(max-width:1024px)');
  const auth = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleLogout = () => {
    handleClose();
    auth.signOut(() => navigate('/'));
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position='static' sx={{ backgroundColor: '#F3F3F7' }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Link to='/'>
          <img
            alt='left-logo'
            src='left-logo.png'
            height='100%'
            width={`${isMobile ? '1' : '2'}00px`}
          ></img>
        </Link>
        <Typography color='#255AA3' variant='h6'>
          TADECO
        </Typography>
        <Stack alignItems='center' justifyContent='center' direction='row'>
          <Stack alignItems='center' justifyContent='center' direction='row'>
            {auth.isLoading ? (
              <CircularProgress size={20} sx={{ marginRight: '30px' }} />
            ) : (
              auth?.token &&
              auth?.user && (
                <>
                  <Typography color='#255AA3'>{auth.user.name}</Typography>
                  <IconButton
                    size='large'
                    aria-label='account of current user'
                    aria-controls='menu-appbar'
                    aria-haspopup='true'
                    onClick={handleMenu}
                    sx={{ color: '#255AA3', marginX: '10px' }}
                  >
                    <AccountCircle />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleLogout}>Salir</MenuItem>
                  </Menu>
                </>
              )
            )}
          </Stack>
          <img
            alt='right-logo'
            src='right-logo.png'
            height={`${isMobile ? '3' : '6'}0px`}
            width={`${isMobile ? '3' : '6'}0px`}
          ></img>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default MenuAppBar;
