import { Box, Typography } from '@mui/material';
import { Acciones } from './components/Acciones/Acciones';
import { IndicadoresPr } from './components/IndicadoresPr/IndicadoresPr';
import { IndicadoresPrAcciones } from './components/IndicadoresPrAcciones/IndicadoresPrAcciones';
import { IndicadoresPrProcesosMacroProcesos } from './components/IndicadoresPrProcesosMacroProcesos/IndicadoresPrProcesosMacroProcesos';
import { IndicadoresPrVariables } from './components/IndicadoresPrVariables/IndicadoresPrVariables';
import { MacroProcesos } from './components/MacroProcesos/MacroProcesos';
import { Parametros } from './components/Parametros/Parametros';
import { Procesos } from './components/Procesos/Procesos';
import { Roles } from './components/Roles/Roles';
import { TipoMacroProcesos } from './components/TipoMacroProcesos/TipoMacroProcesos';
import { TipoParametros } from './components/TipoParametros/TipoParametros';
import { Usuarios } from './components/Usuarios/Usuarios';
import { Variables } from './components/Variables/Variables';
import { Table } from '../Menu';

type Props = {
  activeMenu: Table;
};

const Views: {
  [key in Table]: React.FC<Props>;
} = {
  [Table.ACCIONES]: Acciones,
  [Table.INDICADORES_PR]: IndicadoresPr,
  [Table.INDICADORES_PR_ACCIONES]: IndicadoresPrAcciones,
  [Table.INDICADORES_PR_PROCESOS_MACRO_PROCESOS]:
    IndicadoresPrProcesosMacroProcesos,
  [Table.INDICADORES_PR_VARIABLES]: IndicadoresPrVariables,
  [Table.MACRO_PROCESOS]: MacroProcesos,
  [Table.PARAMETROS]: Parametros,
  [Table.PROCESOS]: Procesos,
  [Table.ROLES]: Roles,
  [Table.TIPO_MACRO_PROCESOS]: TipoMacroProcesos,
  [Table.TIPO_PARAMETROS]: TipoParametros,
  [Table.USUARIOS]: Usuarios,
  [Table.VARIABLES]: Variables,
};

export const Dashboard: React.FC<Props> = ({ activeMenu }) => {
  const View = Views[activeMenu];

  return (
    <Box sx={{ width: '100%', overflowX: 'scroll' }}>
      <View activeMenu={activeMenu} />
      <Typography
        marginLeft='20px'
        marginTop='20px'
        paddingBottom='20px'
        color='#d32f2f'
      >
        * Indica dato obligatorio
      </Typography>
    </Box>
  );
};
