import { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import processApi, { Process } from '../../../../../api/process';
import macroProcessApi, { MacroProcess } from '../../../../../api/macroProcess';
import { useAuth } from '../../../../../Auth/Auth';
import { sortMacroProcessByName } from './utils';
import { filterOnlyActive } from '../utils';

type Props = {
  isEditing?: boolean;
  selectedRow?: Process;
  setSelectedRow?: React.Dispatch<React.SetStateAction<Process | undefined>>;
  fetchProcesses?: () => Promise<void>;
};

const DEFAULT_MACRO_PROCESS_ID = 0;
const DEFAULT_USER_ID = 1;

export const Form: React.FC<Props> = ({
  isEditing = false,
  selectedRow,
  setSelectedRow,
  fetchProcesses,
}) => {
  const auth = useAuth();
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ok, setOk] = useState<boolean>(false);
  const [process, setProcess] = useState<Process>({
    macroProcessId: DEFAULT_MACRO_PROCESS_ID,
    name: '',
    description: '',
    priority: null,
    weight: null,
    risks: '',
    metaNumerics: 0,
    prResult: null,
    lowerLimit: 0,
    upperLimit: 0,
    startValue: 0,
    endValue: 0,
    settingDate: null,
    validityPeriod: '',
    comments: '',
    isActive: 'SI',
    creationId: auth?.user?.id || DEFAULT_USER_ID,
  });
  const [macroProcesses, setMacroProcesses] = useState<MacroProcess[] | []>([]);
  const [macroProcessesError, setMacroProcessesError] = useState<string>('');
  const [isMacroProcessesLoading, setIsMacroProcessesLoading] =
    useState<boolean>(true);

  const fetchMacroProcesses = useCallback(async () => {
    setIsMacroProcessesLoading(true);
    try {
      const responseMacroProcesses = await macroProcessApi.getMacroProcesses();
      const macroProcessesData = responseMacroProcesses?.data ?? [];
      setMacroProcesses(
        filterOnlyActive(sortMacroProcessByName(macroProcessesData))
      );
    } catch {
      setMacroProcessesError(
        'Ha ocurrido un error al obtener los macro procesos.'
      );
    }
    setIsMacroProcessesLoading(false);
  }, []);

  useEffect(() => {
    fetchMacroProcesses();
    if (isEditing && setSelectedRow) {
      setSelectedRow(undefined);
      if (selectedRow) {
        setProcess({
          id: selectedRow.id,
          macroProcessId: selectedRow.macroProcessId,
          name: selectedRow.name,
          description: selectedRow.description ?? '',
          priority: selectedRow.priority,
          weight: selectedRow.weight,
          risks: selectedRow.risks ?? '',
          metaNumerics: selectedRow.metaNumerics,
          prResult: selectedRow.prResult,
          lowerLimit: selectedRow.lowerLimit,
          upperLimit: selectedRow.upperLimit,
          startValue: selectedRow.startValue,
          endValue: selectedRow.endValue,
          settingDate: selectedRow.settingDate,
          validityPeriod: selectedRow.validityPeriod ?? '',
          comments: selectedRow.comments ?? '',
          isActive: selectedRow.isActive,
          creationId: auth?.user?.id || DEFAULT_USER_ID,
        });
      }
    }
  }, [
    auth?.user?.id,
    fetchMacroProcesses,
    isEditing,
    selectedRow,
    setSelectedRow,
  ]);

  const handleOnClickSave = useCallback(async () => {
    setError('');
    setMacroProcessesError('');
    const processId = process.id;
    setIsLoading(true);
    try {
      if (processId) {
        await processApi.updateProcess(processId, process);
      } else {
        await processApi.createProcess(process);
      }
      setOk(true);
    } catch {
      setError('Ha ocurrido un error al guardar el proceso.');
    }
    setProcess({
      macroProcessId: DEFAULT_MACRO_PROCESS_ID,
      name: '',
      description: '',
      priority: null,
      weight: null,
      risks: '',
      metaNumerics: 0,
      prResult: null,
      lowerLimit: 0,
      upperLimit: 0,
      startValue: 0,
      endValue: 0,
      settingDate: null,
      validityPeriod: '',
      comments: '',
      isActive: 'SI',
      creationId: auth?.user?.id || DEFAULT_USER_ID,
    });
    if (isEditing && setSelectedRow) {
      setSelectedRow(undefined);
    }
    setIsLoading(false);
    if (fetchProcesses) {
      await fetchProcesses();
    }
  }, [process, auth?.user?.id, isEditing, setSelectedRow, fetchProcesses]);

  const isMacroProcessesIdInError =
    isEditing &&
    !macroProcesses.some(
      (macroProcess) => macroProcess?.id === process.macroProcessId
    );

  return (
    <>
      <Box
        component='form'
        sx={{
          '& .MuiTextField-root': { m: 1, width: '40ch' },
        }}
        noValidate
        autoComplete='off'
      >
        <TextField
          select
          required
          label='Macro Proceso'
          helperText='Macro Proceso'
          value={
            !macroProcesses.length ||
            !process.macroProcessId ||
            isMacroProcessesIdInError
              ? ''
              : process.macroProcessId
          }
          onChange={(e) =>
            setProcess((prevState) => ({
              ...prevState,
              macroProcessId: e.target.value as unknown as number,
            }))
          }
          error={!!!process.macroProcessId || isMacroProcessesIdInError}
        >
          {macroProcesses.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          type=''
          required
          label='Nombre'
          helperText='Nombre'
          value={process.name ?? ''}
          onChange={(e) =>
            setProcess((prevState) => ({
              ...prevState,
              name: e.target.value,
            }))
          }
          error={!!!process.name}
          inputProps={{ maxLength: 200 }}
        />
        <TextField
          type=''
          label='Descripción'
          helperText='Descripción'
          value={process.description ?? ''}
          onChange={(e) =>
            setProcess((prevState) => ({
              ...prevState,
              description: e.target.value,
            }))
          }
        />
        <TextField
          type='number'
          label='Prioridad'
          helperText='Prioridad'
          value={process.priority ?? ''}
          onChange={(e) =>
            setProcess((prevState) => ({
              ...prevState,
              priority: e.target.value as unknown as number,
            }))
          }
        />
        <TextField
          type='number'
          label='Peso'
          helperText='Peso'
          value={process.weight ?? ''}
          onChange={(e) =>
            setProcess((prevState) => ({
              ...prevState,
              weight: e.target.value as unknown as number,
            }))
          }
          inputProps={{ maxLength: 17 }}
        />
        <TextField
          type=''
          label='Riesgos'
          helperText='Riesgos'
          value={process.risks ?? ''}
          onChange={(e) =>
            setProcess((prevState) => ({
              ...prevState,
              risks: e.target.value,
            }))
          }
        />
        <TextField
          type='number'
          required
          label='Meta Numérica'
          helperText='Meta Numérica'
          value={process.metaNumerics ?? ''}
          onChange={(e) =>
            setProcess((prevState) => ({
              ...prevState,
              metaNumerics: e.target.value as unknown as number,
            }))
          }
          error={process.metaNumerics !== 0 && !!!process.metaNumerics}
          inputProps={{ maxLength: 17 }}
        />
        <TextField
          type='number'
          label='Resultado Proceso'
          helperText='Resultado Proceso'
          value={process.prResult ?? ''}
          onChange={(e) =>
            setProcess((prevState) => ({
              ...prevState,
              prResult: e.target.value as unknown as number,
            }))
          }
          inputProps={{ maxLength: 17 }}
        />
        <TextField
          type='number'
          required
          label='Límite Inferior'
          helperText='Límite Inferior'
          value={process.lowerLimit ?? ''}
          onChange={(e) =>
            setProcess((prevState) => ({
              ...prevState,
              lowerLimit: e.target.value as unknown as number,
            }))
          }
          error={process.lowerLimit !== 0 && !!!process.lowerLimit}
          inputProps={{ maxLength: 17 }}
        />
        <TextField
          type='number'
          required
          label='Límite Superior'
          helperText='Límite Superior'
          value={process.upperLimit ?? ''}
          onChange={(e) =>
            setProcess((prevState) => ({
              ...prevState,
              upperLimit: e.target.value as unknown as number,
            }))
          }
          error={process.upperLimit !== 0 && !!!process.upperLimit}
          inputProps={{ maxLength: 17 }}
        />
        <TextField
          type='number'
          required
          label='Star Value'
          helperText='Star Value'
          value={process.startValue ?? ''}
          onChange={(e) =>
            setProcess((prevState) => ({
              ...prevState,
              startValue: e.target.value as unknown as number,
            }))
          }
          error={process.startValue !== 0 && !!!process.startValue}
          inputProps={{ maxLength: 17 }}
        />
        <TextField
          type='number'
          required
          label='End Value'
          helperText='End Value'
          value={process.endValue ?? ''}
          onChange={(e) =>
            setProcess((prevState) => ({
              ...prevState,
              endValue: e.target.value as unknown as number,
            }))
          }
          error={process.endValue !== 0 && !!!process.endValue}
          inputProps={{ maxLength: 17 }}
        />
        <DatePicker
          sx={{
            '& .Mui-error': { color: '#00000099 !important' },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#00000099 !important',
            },
          }}
          label='Fecha de Parametrización'
          slotProps={{
            textField: {
              helperText: 'DD/MM/AAAA',
            },
          }}
          value={dayjs(process.settingDate ?? '')}
          onChange={(newValue) =>
            setProcess((prevState) => ({
              ...prevState,
              settingDate: newValue
                ? dayjs(newValue).format('YYYY-MM-DD')
                : null,
            }))
          }
        />
        <TextField
          type=''
          label='Período de Validez'
          helperText='Período de Validez'
          value={process.validityPeriod ?? ''}
          onChange={(e) =>
            setProcess((prevState) => ({
              ...prevState,
              validityPeriod: e.target.value,
            }))
          }
          inputProps={{ maxLength: 200 }}
        />
        <TextField
          type=''
          label='Observaciones'
          helperText='Observaciones'
          value={process.comments ?? ''}
          onChange={(e) =>
            setProcess((prevState) => ({
              ...prevState,
              comments: e.target.value,
            }))
          }
        />
        {isEditing && (
          <TextField
            select
            required
            label='Activo'
            value={process.isActive}
            onChange={(e) =>
              setProcess((prevState) => ({
                ...prevState,
                isActive: e.target.value,
              }))
            }
          >
            {[
              { value: 'SI', label: 'SI' },
              { value: 'NO', label: 'NO' },
            ].map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Box>
      <Button
        variant='contained'
        sx={{ textTransform: 'none' }}
        onClick={handleOnClickSave}
        disabled={
          isLoading ||
          isMacroProcessesLoading ||
          !!!process.macroProcessId ||
          !!!process.name ||
          (process.metaNumerics !== 0 && !!!process.metaNumerics) ||
          (process.lowerLimit !== 0 && !!!process.lowerLimit) ||
          (process.upperLimit !== 0 && !!!process.upperLimit) ||
          (process.startValue !== 0 && !!!process.startValue) ||
          (process.endValue !== 0 && !!!process.endValue) ||
          isMacroProcessesIdInError ||
          !!!process.isActive ||
          (isEditing && !!!process.id)
        }
      >
        {isLoading || isMacroProcessesLoading ? (
          <CircularProgress size={25} />
        ) : (
          'Guardar'
        )}
      </Button>
      {(error || macroProcessesError) && (
        <Typography color='red'>{error || macroProcessesError}</Typography>
      )}
      {ok && (
        <Typography marginTop={'10px'}>
          El proceso se ha guardado correctamente.
        </Typography>
      )}
    </>
  );
};
