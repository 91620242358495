import { useState, useCallback } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import parameterApi, { Parameter } from '../../../../../api/parameter';
import { Form } from './Form';
import { Table } from '../shared/Table';

type Props = {
  action: string;
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'name', headerName: 'Nombre', width: 130 },
  { field: 'typeParameterId', headerName: 'Tipo de Parámetro', width: 100 },
  { field: 'comments', headerName: 'Observaciones', width: 130 },
  {
    field: 'isActive',
    headerName: 'Activo',
    width: 90,
  },
];

export const Wrapper: React.FC<Props> = ({ action }) => {
  const [selectedRow, setSelectedRow] = useState<Parameter | undefined>();
  const [parameters, setParameters] = useState<Parameter[] | []>([]);
  const [tableError, setTableError] = useState<string>('');
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true);

  const fetchParameters = useCallback(async () => {
    setIsTableLoading(true);
    try {
      const responseParameters = await parameterApi.getParameters();
      const parametersData = responseParameters?.data;
      setParameters(parametersData);
    } catch {
      setTableError('Ha ocurrido un error al obtener los usuarios.');
    }
    setIsTableLoading(false);
  }, []);

  return action === 'add' ? (
    <Form />
  ) : (
    <>
      <Table
        setSelectedRow={setSelectedRow}
        rows={parameters}
        columns={columns}
        error={tableError}
        isLoading={isTableLoading}
        setIsTableLoading={setIsTableLoading}
        fetchTable={fetchParameters}
      />
      <Form
        isEditing={action === 'modify'}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        fetchParameters={fetchParameters}
      />
    </>
  );
};
