import { useState, useCallback } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import userApi, { User } from '../../../../../api/user';
import { Form } from './Form';
import { Table } from '../shared/Table';

type Props = {
  action: string;
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'name', headerName: 'Nombre', width: 130 },
  { field: 'firstLastname', headerName: 'Primer Apellido', width: 200 },
  { field: 'secondLastname', headerName: 'Segundo Apellido', width: 200 },
  { field: 'email', headerName: 'Email', width: 200 },
  { field: 'address', headerName: 'Dirección', width: 200 },
  { field: 'telephone', headerName: 'Teléfono', width: 200 },
  { field: 'roleId', headerName: 'Rol', width: 100 },
  { field: 'password', headerName: 'Password', width: 130 },
  {
    field: 'isActive',
    headerName: 'Activo',
    width: 90,
  },
];

export const Wrapper: React.FC<Props> = ({ action }) => {
  const [selectedRow, setSelectedRow] = useState<User | undefined>();
  const [users, setUsers] = useState<User[] | []>([]);
  const [tableError, setTableError] = useState<string>('');
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true);

  const fetchUsers = useCallback(async () => {
    setIsTableLoading(true);
    try {
      const responseUsers = await userApi.getUsers();
      const usersData = responseUsers?.data;
      setUsers(usersData);
    } catch {
      setTableError('Ha ocurrido un error al obtener los usuarios.');
    }
    setIsTableLoading(false);
  }, []);

  return action === 'add' ? (
    <Form />
  ) : (
    <>
      <Table
        setSelectedRow={setSelectedRow}
        rows={users}
        columns={columns}
        error={tableError}
        isLoading={isTableLoading}
        setIsTableLoading={setIsTableLoading}
        fetchTable={fetchUsers}
      />
      <Form
        isEditing={action === 'modify'}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        fetchUsers={fetchUsers}
      />
    </>
  );
};
