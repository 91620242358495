import { MacroProcess } from '../../../../../api/macroProcess';
import { Process } from '../../../../../api/process';

export const sortMacroProcessByName = (
  macroProcesses: MacroProcess[]
): MacroProcess[] =>
  macroProcesses.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  });

export const sortProcessByName = (processes: Process[]): Process[] =>
  processes.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  });
