import { Action } from '../../../../../api/action';

export const sortActionByName = (actions: Action[]): Action[] =>
  actions.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  });
