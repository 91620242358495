import { useState, useCallback } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import processApi, { Process } from '../../../../../api/process';
import { Form } from './Form';
import { Table } from '../shared/Table';

type Props = {
  action: string;
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  {
    field: 'macroProcessId',
    headerName: 'Macro Proceso',
    width: 100,
  },
  { field: 'name', headerName: 'Nombre', width: 130 },
  { field: 'description', headerName: 'Descripción', width: 200 },
  { field: 'priority', headerName: 'Prioridad', width: 130 },
  { field: 'weight', headerName: 'Peso', width: 130 },
  { field: 'risks', headerName: 'Riesgos', width: 130 },
  { field: 'metaNumerics', headerName: 'Meta Numérica', width: 130 },
  { field: 'prResult', headerName: 'Resultado Proceso', width: 130 },
  { field: 'lowerLimit', headerName: 'Límite Inferior', width: 130 },
  { field: 'upperLimit', headerName: 'Límite Superior', width: 130 },
  { field: 'startValue', headerName: 'Star Value', width: 130 },
  { field: 'endValue', headerName: 'End Value', width: 130 },
  { field: 'settingDate', headerName: 'Fecha de Parametrización', width: 130 },
  { field: 'validityPeriod', headerName: 'Período de Validez', width: 130 },
  { field: 'comments', headerName: 'Observaciones', width: 200 },
  {
    field: 'isActive',
    headerName: 'Activo',
    width: 90,
  },
];

export const Wrapper: React.FC<Props> = ({ action }) => {
  const [selectedRow, setSelectedRow] = useState<Process | undefined>();
  const [processes, setProcesses] = useState<Process[] | []>([]);
  const [tableError, setTableError] = useState<string>('');
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true);

  const fetchProcesses = useCallback(async () => {
    setIsTableLoading(true);
    try {
      const responseProcesses = await processApi.getProcesses();
      const processesData = responseProcesses?.data;
      setProcesses(processesData);
    } catch {
      setTableError('Ha ocurrido un error al obtener los procesos.');
    }
    setIsTableLoading(false);
  }, []);

  return action === 'add' ? (
    <Form />
  ) : (
    <>
      <Table
        setSelectedRow={setSelectedRow}
        rows={processes}
        columns={columns}
        error={tableError}
        isLoading={isTableLoading}
        setIsTableLoading={setIsTableLoading}
        fetchTable={fetchProcesses}
      />
      <Form
        isEditing={action === 'modify'}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        fetchProcesses={fetchProcesses}
      />
    </>
  );
};
