import { Variable } from '../../../../../api/variable';

export const sortVariableByName = (variables: Variable[]): Variable[] =>
  variables.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  });
